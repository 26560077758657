import { message } from "antd";
import axios from "axios";
import { errorActions } from "../helpers/auth_helper";

export const getPOVendorDashboardData = async (params: any) => {
  try {
    console.log(params);
    const emptyFilteredParams = Object.keys(params).reduce((acc, key) => {
      if (params[key] !== "") {
        acc[key] = params[key];
      }
      return acc;
    }, {} as Record<string, string>);

    return axios
      .get("https://api-dev2.materialdepot.in/apiV1/po-vendor-dashboard/", {
        params: { ...emptyFilteredParams },
      })
      .then((res) => {
        return res?.data;
      })
      .catch((error) => {
        errorActions(
          error,
          "Error getting PO Vendor Dashboard data. Please try again."
        );
        return;
      });
  } catch (e) {
    message.error("Error getting PO Vendor Dashboard data. Please try again.");
    return null;
  }
};

export const getPOMarginData = async (params: any) => {
  try {
    const emptyFilteredParams = Object.keys(params).reduce((acc, key) => {
      if (params[key] !== "") {
        acc[key] = params[key];
      }
      return acc;
    }, {} as Record<string, string>);
    return axios
      .get("https://api-dev2.materialdepot.in/apiV1/po-margin-dashboard/", {
        params: { ...emptyFilteredParams },
      })
      .then((res) => {
        return res?.data;
      })
      .catch((error) => {
        errorActions(error, "Error getting PO Margin data. Please try again.");
        return;
      });
  } catch (e) {
    message.error("Error getting PO Margin data. Please try again.");
    return null;
  }
};

export const getPOLog = async (params: any) => {
  try {
    return axios
      .get("https://api-dev2.materialdepot.in/apiV1/po-log/", {
        params: { ...params },
      })
      .then((res) => {
        return res?.data;
      })
      .catch((error) => {
        errorActions(error, "Error getting PO Log data. Please try again.");
        return;
      });
  } catch (e) {
    message.error("Error getting PO Log data. Please try again.");
    return null;
  }
};

export const createPurchaseOrder = async (value: any) => {
  try {
    return axios
      .post("https://api-dev2.materialdepot.in/apiV1/po/", value)
      .then((res) => {
        message.success("PO created succesfully");
      })
      .catch((error) => {
        errorActions(error, "Error creating PO. Please try again.");
        return;
      });
  } catch (error) {
    message.error("Error creating PO. Please try again.");
    return null;
  }
};

export const editPurchaseOrder = async (po_id: any, params: any) => {
  try {
    return axios
      .put("apiV1/po/" + po_id + "/", params)
      .then((res) => {
        message.success("PO edited succesfully");
      })
      .catch((error) => {
        errorActions(error, "Error editing PO. Please try again.");
        return false;
      });
  } catch (error) {
    message.error("Error in editing PO.");
    return false;
  }
};

export const getPurchaseOrders = async (params: any) => {
  try {
    return axios
      .get("https://api-dev2.materialdepot.in/apiV1/po/", {
        params: { ...params },
      })
      .then((res) => {
        return res?.data;
      })
      .catch((error) => {
        errorActions(error, "Error getting PO. Please try again.");
        return;
      });
  } catch (error) {}
};

export const getPurchaseOrderDetails = async (po_id: string) => {
  try {
    return axios
      .get("https://api-dev2.materialdepot.in/apiV1/po/" + po_id)
      .then((res) => {
        return res?.data;
      })
      .catch((error) => {
        errorActions(error, "Error getting PO details. Please try again.");
        return;
      });
  } catch (error) {
    return null;
  }
};

export const resendPOEmail = async (po_id: string) => {
  try {
    return axios
      .get(
        "https://api-dev2.materialdepot.in/apiV1/po-email-resend/?po_id=" +
          po_id
      )
      .then((res) => {
        message.success("Mail sent.");
        return res?.data;
      })
      .catch((error) => {
        errorActions(error, "Error getting PO details. Please try again.");
        return;
      });
  } catch (error) {
    return null;
  }
};

export const cancelPurchaseOrder = async (
  po_id: string | undefined,
  formData: FormData
) => {
  try {
    return axios
      .put(`apiV1/po/${po_id}/`, formData)
      .then((res) => {
        message.success("Successfully cancelled PO!");
        window.history.back();
      })
      .catch(() => {
        message.error("Something went wrong!");
      });
  } catch (error) {
    return null;
  }
};

export const addInvoiceToPo = async (value: any) => {
  try {
    return axios
      .post("https://api-dev2.materialdepot.in/apiV1/po-invoice/", value)
      .then((res) => {
        message.success("Invoice added succesfully");
      })
      .catch((error) => {
        errorActions(error, "Error creating PO. Please try again.");
        return;
      });
  } catch (error) {
    message.error("Error creating PO. Please try again.");
    return null;
  }
};

export const removeInvoiceFromPo = async (id: any) => {
  try {
    return axios
      .delete("apiV1/po-invoice/" + id + "/")
      .then((res) => {
        message.success("Invoice removed succesfully");
      })
      .catch((error) => {
        errorActions(error, "Error removing invoice. Please try again.");
        return;
      });
  } catch (error) {
    message.error("Error creating PO. Please try again.");
    return null;
  }
};

export const getPOInvoices = async (po_id: any) => {
  try {
    return axios
      .get("https://api-dev2.materialdepot.in/apiV1/po-invoice/?po=" + po_id)
      .then((res) => {
        return res?.data;
      })
      .catch((error) => {
        errorActions(error, "Error creating PO. Please try again.");
        return;
      });
  } catch (error) {
    message.error("Error creating PO. Please try again.");
    return null;
  }
};

export const rocketBoxOrderCreation = async (po_id: any) => {
  try {
    return axios
      .get(
        "https://api-dev2.materialdepot.in/apiV1/rocketbox-order-creation/?po_id=" +
          po_id
      )
      .then((res) => {
        message.success("Order created succesfully");
        return res?.data;
      })
      .catch((error) => {
        errorActions(error, "Error creating Order. Please try again.");
        return;
      });
  } catch (error) {
    message.error("Error creating Order. Please try again.");
    return null;
  }
};

export const rocketBoxShipmentCreation = async (po_id: any) => {
  try {
    return axios
      .get(
        "https://api-dev2.materialdepot.in/apiV1/rocketbox-shipment-creation/?po_id=" +
          po_id
      )
      .then((res) => {
        message.success("Shipment created succesfully");
        return res?.data;
      })
      .catch((error) => {
        errorActions(error, "Error creating Order. Please try again.");
        return;
      });
  } catch (error) {
    message.error("Error creating Order. Please try again.");
    return null;
  }
};

export const rocketBoxShipmentTracking = async (po_id: any) => {
  try {
    return axios
      .get(
        "https://api-dev2.materialdepot.in/apiV1/rocketbox-tracking/?po_id=" +
          po_id
      )
      .then((res) => {
        message.success("Tracking created succesfully");
        return res?.data;
      })
      .catch((error) => {
        errorActions(error, "Error creating Order. Please try again.");
        return;
      });
  } catch (error) {
    message.error("Error creating Order. Please try again.");
    return null;
  }
};

export const exportPOCSV = async (params: any) => {
  try {
    return axios
      .get("https://api-dev2.materialdepot.in/apiV1/po-export-csv/", {
        params: { ...params },
      })
      .then((res) => {
        return res?.data;
      })
      .catch((error) => {
        errorActions(error, "Error exporting PO data. Please try again.");
        return;
      });
  } catch (error) {
    message.error("Error exporting PO data. Please try again.");
    return null;
  }
};

export const pushPOInvoiceToZoho = async (
  po_id: any,
  estimate_id: any,
  stratos = false
) => {
  try {
    message.loading({ content: "Pushing PO to Zoho", key: "pushPOToZoho" });
    return axios
      .get(
        "https://api-dev2.materialdepot.in/apiV1/zoho-invoice/?po_id=" +
          po_id +
          "&estimate_id=" +
          estimate_id +
          (stratos ? "&raise_to_stratos=true" : "")
      )
      .then((res) => {
        // message.success("PO pushed to Zoho succesfully");
        message.success({
          content: "PO pushed to Zoho succesfully",
          key: "pushPOToZoho",
        });
        return res?.data;
      })
      .catch((error) => {
        // errorActions(error, "Error pushing PO to Zoho. Please try again.");
        const errorData = error?.response?.data;
        const errorMessage = errorData?.message ?? "";
        message.error({
          content: `Error pushing PO to Zoho. Please try again. ${errorMessage}`,
          key: "pushPOToZoho",
        });
        return;
      });
  } catch (error) {
    message.error("Error pushing PO to Zoho. Please try again.");
    return null;
  }
};

export const pushPOPurchaseOrderToZoho = async (po_id: any) => {
  try {
    message.loading({
      content: "Pushing PO to Zoho",
      key: "pushPOInvoiceToZoho",
    });
    return axios
      .get(
        "https://api-dev2.materialdepot.in/apiV1/zoho-purchase-order/?po_id=" +
          po_id
      )
      .then((res) => {
        // message.success("PO pushed to Zoho succesfully");
        message.success({
          content: "PO pushed to Zoho succesfully",
          key: "pushPOInvoiceToZoho",
        });
        return res?.data;
      })
      .catch((error) => {
        // errorActions(error, "Error pushing PO to Zoho. Please try again.");
        message.error({
          content: "Error pushing PO to Zoho. Please try again.",
          key: "pushPOInvoiceToZoho",
        });
        return;
      });
  } catch (error) {
    message.error("Error pushing PO to Zoho. Please try again.");
    return null;
  }
};

export const createLoginextOrders = async (
  start_date?: string,
  end_date?: string
) => {
  try {
    message.loading({
      content: "Creating orders in LogiNext",
      key: "createLoginextOrders",
    });

    return axios
      .get("https://api-dev2.materialdepot.in/apiV1/create-loginext-orders/", {
        params: { start_date, end_date },
      })
      .then((res) => {
        message.success({
          content: "Pushed POs to LogiNext successfully",
          key: "createLoginextOrders",
        });
        return res?.data;
      });
  } catch (error) {
    message.error({
      content: "Error creating orders. Please try again.",
      key: "createLoginextOrders",
    });
    return null;
  }
};

export const createLoginextOrder = async ({
  po_number,
  depot,
  dispatch_date,
}: {
  po_number: string;
  depot?: string;
  dispatch_date?: string;
}) => {
  try {
    message.loading({
      content: "Creating order in LogiNext",
      key: "createLoginextOrder",
    });

    return axios
      .post("https://api-dev2.materialdepot.in/apiV1/create-loginext-order/", {
        po_number,
        depot,
        dispatch_date,
      })
      .then((res) => {
        message.success({
          content: "Pushed PO to LogiNext successfully",
          key: "createLoginextOrder",
        });
        return res?.data;
      });
  } catch (error) {
    message.error({
      content: "Error creating order. Please try again.",
      key: "createLoginextOrder",
    });
    return null;
  }
};

export const getLoginextTrips = async (params: any) => {
  try {
    return axios
      .get("https://api-dev2.materialdepot.in/apiV1/loginext-trip/", {
        params: { ...params },
      })
      .then((res) => {
        return res?.data;
      });
  } catch (error) {
    return null;
  }
};

export const createPidgeOrder = async (po_id: number) => {
  try {
    return axios
      .get("https://api-dev2.materialdepot.in/apiV1/push-to-pidge/", {
        params: {
          po_id: po_id,
        },
      })
      .then((res) => {
        return res?.data;
      });
  } catch (error) {
    return null;
  }
};

export const getLoginextOrderETAs = async (dispatch_date: string) => {
  try {
    return axios
      .get("https://api-dev2.materialdepot.in/apiV1/loginext-order-eta/", {
        params: { dispatch_date },
      })
      .then((res) => {
        return res?.data;
      });
  } catch (error) {
    return null;
  }
};

export const getPOLogByLoginextTrip = async (params: Record<string, any>) => {
  try {
    return axios
      .get("https://api-dev2.materialdepot.in/apiV1/po-log-by-trip/", {
        params: { ...params },
      })
      .then((res) => {
        return res?.data;
      });
  } catch (error) {
    return null;
  }
};
