import {
  Alert,
  Button,
  Drawer,
  Form,
  FormInstance,
  Input,
  message,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { fetchUserDetails } from "../../../apis/user.api";

interface DeliveryLocationDrawerProps {
  visible: boolean;
  onFinish: Function;
  onClose: Function;
}

const DeliveryLocationDrawer = ({
  visible,
  onFinish,
  onClose,
}: DeliveryLocationDrawerProps) => {
  const [userDetails, setUserDetails] = useState<any>(null);

  useEffect(() => {
    fetchUserDetails().then((data) => {
      setUserDetails(data);
    });
  }, []);

  const formRef = React.useRef<FormInstance>(null);
  const [porterError, setPorterError] = useState(false);
  const [live_location_link, setLiveLocationLink] = useState("");

  const onSubmit = () => {
    if (live_location_link?.length > 0) {
      try {
        if (live_location_link.includes("porter.in")) {
          const url = new URL(live_location_link);
          // Check if it has the following query params: booking_id, customer_uuid
          if (
            url.searchParams.has("booking_id") &&
            url.searchParams.has("customer_uuid")
          ) {
            setPorterError(false);
          } else {
            setPorterError(true);
            return;
          }
        } else {
          // If the URL is not from porter.in unless the current user's id is "ee64d1ed-4baf-4e66-850e-40f70feb0063"
          if (userDetails?.id !== "ee64d1ed-4baf-4e66-850e-40f70feb0063") {
            setPorterError(true);
            return;
          }
        }
      } catch (error) {
        setPorterError(true);
        return;
      }
      onFinish({
        ...formRef.current?.getFieldsValue(),
        location_link_entry_time: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
      });
    } else {
      message.warning("Please select ship to address");
    }
  };
  useEffect(() => {
    formRef.current?.resetFields();
  }, []);
  return (
    <>
      <Drawer
        title="Delivery details"
        placement="right"
        open={visible}
        closable={true}
        onClose={() => {
          onClose();
        }}
        width={600}
        footer={
          <div className="d-flex gap-2">
            <Button onClick={() => onClose(true)}>Cancel</Button>
            <Button type="primary" onClick={onSubmit}>
              Save
            </Button>
          </div>
        }
      >
        {porterError && (
          <Alert
            message="Please enter a valid porter link. URL must have the valid tracking parameters."
            type="error"
            showIcon
          />
        )}
        <Form layout="vertical" onFinish={onSubmit} ref={formRef}>
          <Form.Item
            name="live_location_link"
            label="Live location link"
            rules={[
              {
                required: true,
              },
              {
                type: "url",
                message: "Please enter a valid URL",
              },
            ]}
          >
            <Input
              onChange={(e) => {
                setLiveLocationLink(e.target.value);
              }}
            />
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
};

export default DeliveryLocationDrawer;
