import axios from "axios";

const BIK_APP_ID = "ABI0GWT2mvo1gwMsQAE6";
const BIK_APP_SECRET = "128c74f2678a832d9654";
const BIK_KEY = "0f5baebffe";

export type BikEvent = "addToCart";

export async function createOrUpdateBikUser(user: {
  f_name?: string;
  l_name?: string;
  contact: string;
  email?: string;
  pincode?: string;
  user_source?: string;
}) {
  let city = "Unknown";
  let state = "Unknown";
  try {
    const pincodeResponse = await axios.get(
      "https://api.postalpincode.in/pincode/" + user?.pincode
    );
    const pincodeData = pincodeResponse?.data[0];
    if (pincodeData) {
      city = pincodeData?.PostOffice[0]?.District;
      state = pincodeData?.PostOffice[0]?.State;
    }
  } catch (e) {}

  try {
    await axios.post(
      "https://bikapi.bikayi.app/integrations/bikPlatformFunctions-createOrUpdateUserProperties",
      {
        appId: process.env.NEXT_PUBLIC_BIK_APP_ID,
        userProperties: [
          {
            firstName: user?.f_name,
            lastName: user?.l_name ?? "_",
            phoneNumber: `+91${user?.contact}`,
            email: user?.email,
            name: user?.f_name,
            zip: user?.pincode ? String(user?.pincode) : undefined,
            city: city,
            state: state,
            country: "India",
            source: user?.user_source ?? undefined,
          },
        ],
      },
      {
        auth: {
          username: BIK_KEY,
          password: BIK_APP_SECRET,
        },
      }
    );
  } catch (e) {
    console.error("BikLogger", "createOrUpdateUser", e);
    return null;
  }
}

export async function logBikEvent(
  event: BikEvent,
  data: {
    productId: string;
    productName: string;
    variantId: string;
    variantName: string;
    price: number;
  },
  user: {
    f_name?: string;
    contact: string;
    email?: string;
  }
) {
  try {
    const response = await axios.post(
      "https://bikapi.bikayi.app/integrations/bikPlatformFunctions-sendEvent",
      {
        appId: BIK_APP_ID,
        event: {
          name: user.f_name,
          phoneNumber: `+91${user.contact}`,
          email: user.email,
          eventName: event,
          eventProperties: data,
        },
      },
      {
        auth: {
          username: BIK_KEY,
          password: BIK_APP_SECRET,
        },
      }
    );

    console.log("BikLogger", "logEvent", response.data);

    return response.data;
  } catch (e) {
    console.error("BikLogger", "logEvent", e);

    return null;
  }
}
