import { CheckCircleFilled, WarningFilled } from "@ant-design/icons";
import {
  Collapse,
  DatePicker,
  Empty,
  message,
  Select,
  Space,
  Table,
  Typography,
} from "antd";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { editDelivery, getDeliveries } from "../../../../apis/misc.api";

export function TrackerByDeliveries() {
  const [deliveries, setDeliveries] = useState<any[]>([]);
  const [currentTime, setCurrentTime] = useState(moment());
  const [dateStart, setDateStart] = useState<any>();
  const [dateEnd, setDateEnd] = useState<any>();

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(moment());
    }, 60 * 1000);

    const date = moment();
    const today = date.startOf("day").format("YYYY-MM-DD HH:mm:ss");
    const tomorrow = date
      .add(1, "day")
      .startOf("day")
      .format("YYYY-MM-DD HH:mm:ss");

    setDateStart(today);
    setDateEnd(tomorrow);

    return () => clearInterval(interval);
  }, []);

  const fetchDeliveries = useCallback(async () => {
    // Get date in format of YYYY-MM-DD 00:00:00
    // And tomorrow date in format of YYYY-MM-DD 00:00:00
    // const today = moment().startOf("day").format("YYYY-MM-DD HH:mm:ss");
    // const tomorrow = moment()
    //   .add(1, "day")
    //   .startOf("day")
    // //   .format("YYYY-MM-DD HH:mm:ss");
    // const today = "2026-11-30 00:00:00";
    // const tomorrow = "2026-12-01 00:00:00";

    if (!dateStart || !dateEnd) {
      return;
    }

    const todayDeliveries = await getDeliveries({
      po__dispatch_time__lte: dateEnd,
      po__dispatch_time__gte: dateStart,
      page_size: 1000,
    });

    setDeliveries(
      todayDeliveries?.results?.filter(
        (delivery: any) => !delivery?.is_deleted
      ) || []
    );
  }, [dateStart, dateEnd]);

  useEffect(() => {
    fetchDeliveries();
  }, [fetchDeliveries]);

  // Re-fetch data every 5 minutes
  useEffect(() => {
    const interval = setInterval(() => {
      fetchDeliveries();
    }, 5 * 60 * 1000);

    return () => clearInterval(interval);
  }, [fetchDeliveries]);

  const groupedTrips = useMemo(() => {
    return deliveries.reduce((acc, delivery) => {
      const tripName = delivery.trip_name ?? "Unassigned";
      if (!acc[tripName]) {
        acc[tripName] = [];
      }

      acc[tripName].push(delivery);

      return acc;
    }, {} as any);
  }, [deliveries]);

  const editDeliveryStatus = useCallback(
    async (id: number, status: string) => {
      try {
        editDelivery({
          id: id,
          last_updated_status: status,
        });
        message.success("Delivery status updated successfully");

        fetchDeliveries();
      } catch (e) {
        message.error("Failed to update delivery status");
      }
    },
    [fetchDeliveries]
  );

  const columns = useMemo(
    () => [
      {
        title: "PO Number",
        key: "po.po_number",
        render: (record: any) => {
          return record.po?.po_number;
        },
      },
      {
        title: "Lead ID",
        key: "lead_id",
        dataIndex: "lead_id",
      },
      {
        title: "Delivery Status",
        key: "delivery_status",
        render: (record: any) => {
          return (
            <Select
              value={record?.last_updated_status}
              style={{ width: 140 }}
              onChange={(value) => {
                editDeliveryStatus(record.id, value);
              }}
              disabled={record?.last_updated_status === "delivered"}
            >
              <Select.Option value="created">Manifested</Select.Option>
              <Select.Option value="picked_up">Picked Up</Select.Option>
              <Select.Option value="dispatched">Picked Up</Select.Option>
              <Select.Option value="delivered">Delivered</Select.Option>
            </Select>
          );
        },
      },
      {
        title: "Vendor Name",
        key: "po.vendor_name",
        render: (record: any) => {
          return <div style={{ width: 170 }}>{record?.vendor_data?.name}</div>;
        },
      },
      // {
      //   title: "Delivery Person",
      //   dataIndex: "delivery_person_name",
      //   key: "delivery_person_name",
      // },
      {
        title: "Pickup Planned ETA",
        dataIndex: "pickup_planned_eta",
        render: (data: any, record: any) => {
          const date = moment(data).format("YYYY-MM-DD HH:mm:ss");

          const diff = moment(currentTime).diff(
            record?.pickup_planned_eta,
            "minutes"
          );

          if (record?.trip_name) {
            const currentSequence = record?.pickup_sequence;

            // Get last touchpoint by sorting by modified_at of the delivery record and figure out which time to consider
            // by considering deliver_time if present else pickup_time
            const pickupsAndDeliveries = groupedTrips[record.trip_name];
            const lastTouchPoint = pickupsAndDeliveries
              .filter((point: any) => {
                return (
                  point.pickup_sequence < currentSequence &&
                  (point.pickup_time || point.delivery_time)
                );
              })
              .sort((a: any, b: any) => {
                return moment(a.modified_at).diff(moment(b.modified_at));
              })
              .pop();

            const timeToConsider =
              lastTouchPoint?.delivery_time || lastTouchPoint?.pickup_time;
            const timeToDiffAgainst = lastTouchPoint?.delivery_time
              ? lastTouchPoint?.delivery_planned_eta
              : lastTouchPoint?.pickup_planned_eta;

            const previousPickupDiff = lastTouchPoint
              ? moment(timeToConsider).diff(
                  moment(timeToDiffAgainst),
                  "minutes"
                )
              : 0;

            const totalDiff = diff + previousPickupDiff;

            return (
              <>
                {date}{" "}
                {!record?.pickup_time && (
                  <>
                    (Δ {totalDiff} mins){" "}
                    {totalDiff > 20 && (
                      <WarningFilled style={{ color: "red" }} />
                    )}
                  </>
                )}
              </>
            );
          }

          return (
            <>
              {date}{" "}
              {!record?.pickup_time && (
                <>
                  (Δ {diff} mins){" "}
                  {diff > 20 && <WarningFilled style={{ color: "red" }} />}
                </>
              )}
            </>
          );
        },
      },
      {
        title: "Actual Pickup Time",
        dataIndex: "pickup_time",
        render: (data: any, record?: any) => {
          if (!data) {
            return <>--</>;
          }

          const date = moment(data).format("HH:mm:ss");
          return (
            <Space>
              <span>{date}</span>
              <span>
                (Δ{" "}
                {moment(data).diff(
                  moment(record?.pickup_planned_eta),
                  "minutes"
                )}{" "}
                mins)
              </span>
              {moment(data).diff(
                moment(record?.pickup_planned_eta),
                "minutes"
              ) > 20 ? (
                <WarningFilled style={{ color: "red" }} />
              ) : (
                <CheckCircleFilled style={{ color: "green" }} />
              )}
            </Space>
          );
        },
      },
      {
        title: "Delivery Planned ETA",
        dataIndex: "delivery_planned_eta",
        render: (data: any, record: any) => {
          const date = moment(data).format("HH:mm:ss");

          const diff = currentTime.diff(
            moment(record?.delivery_planned_eta),
            "minutes"
          );

          if (record?.trip_name) {
            const currentSequence = record?.delivery_sequence;

            // Get last touchpoint by sorting by modified_at of the delivery record and figure out which time to consider
            // by considering deliver_time if present else pickup_time
            const pickupsAndDeliveries = groupedTrips[record.trip_name];
            const lastTouchPoint = pickupsAndDeliveries
              .filter((point: any) => {
                return (
                  point.pickup_sequence < currentSequence &&
                  (point.pickup_time || point.delivery_time)
                );
              })
              .sort((a: any, b: any) => {
                return moment(a.modified_at).diff(moment(b.modified_at));
              })
              .pop();

            const timeToConsider =
              lastTouchPoint?.delivery_time || lastTouchPoint?.pickup_time;
            const timeToDiffAgainst = lastTouchPoint?.delivery_time
              ? lastTouchPoint?.delivery_planned_eta
              : lastTouchPoint?.pickup_planned_eta;

            const previousDeliveryDiff = lastTouchPoint
              ? moment(timeToConsider).diff(
                  moment(timeToDiffAgainst),
                  "minutes"
                )
              : 0;

            const totalDiff = diff + previousDeliveryDiff;

            return (
              <>
                {date}{" "}
                {!record?.delivery_time && (
                  <>
                    (Δ {totalDiff} mins){" "}
                    {totalDiff > 20 && (
                      <WarningFilled style={{ color: "red" }} />
                    )}
                  </>
                )}
              </>
            );
          }

          return (
            <>
              {date}{" "}
              {!record?.delivery_time && (
                <>
                  (Δ {diff} mins){" "}
                  {diff > 20 && <WarningFilled style={{ color: "red" }} />}
                </>
              )}
            </>
          );
        },
      },
      {
        title: "Actual Delivery Time",
        dataIndex: "delivery_time",
        render: (data: any, record?: any) => {
          if (!data) {
            return <>--</>;
          }

          const date = moment(data).format("HH:mm:ss");

          const diff = moment(data).diff(
            moment(record?.delivery_planned_eta),
            "minutes"
          );

          return (
            <Space>
              <span>{date}</span>
              <span>(Δ {diff} mins)</span>
              {diff > 20 ? (
                <WarningFilled style={{ color: "red" }} />
              ) : (
                <CheckCircleFilled style={{ color: "green" }} />
              )}
            </Space>
          );
        },
      },
    ],
    [currentTime, editDeliveryStatus, groupedTrips]
  );

  const lastPickedUpDelivery = useMemo(() => {
    // Sort by pickup_sequence and get the last delivery with pickup_time
    return deliveries
      .filter((delivery) => delivery.pickup_time)
      .sort((a, b) => a.pickup_sequence - b.pickup_sequence)
      .pop();
  }, [deliveries]);

  const nextPickupDelivery = useCallback(
    (tripName: string) => {
      // Sort by pickup_sequence and get the first delivery without pickup_time
      // return deliveries
      //   .filter((delivery) => !delivery.pickup_time)
      //   .sort((a, b) => a.pickup_sequence - b.pickup_sequence)
      //   .shift();
      return groupedTrips[tripName]
        .filter((delivery: any) => !delivery.pickup_time)
        .sort((a: any, b: any) => a.pickup_sequence - b.pickup_sequence)
        .shift();
    },
    [groupedTrips]
  );

  const lastDeliveredDelivery = useMemo(() => {
    // Sort by delivery_sequence and get the last delivery with delivery_time
    return deliveries
      .filter((delivery) => delivery.delivery_time)
      .sort((a, b) => a.delivery_sequence - b.delivery_sequence)
      .pop();
  }, [deliveries]);

  const nextDelivery = useCallback(
    (tripName: string) => {
      // Sort by delivery_sequence and get the first delivery without delivery_time
      // return deliveries
      //   .filter((delivery) => !delivery.delivery_time)
      //   .sort((a, b) => a.delivery_sequence - b.delivery_sequence)
      //   .shift();
      return groupedTrips[tripName]
        .filter((delivery: any) => !delivery.delivery_time)
        .sort((a: any, b: any) => a.delivery_sequence - b.delivery_sequence)
        .shift();
    },
    [groupedTrips]
  );

  return (
    <main>
      <div className="d-flex justify-content-between mb-3">
        <div />
        <Space>
          <Typography.Text>Current Time:</Typography.Text>
          <Typography.Text>
            {currentTime.format("YYYY-MM-DD HH:mm:ss")}
          </Typography.Text>
          <Typography.Text>Dispatch Date:</Typography.Text>
          <DatePicker
            onChange={(date) => {
              const start = date?.startOf("day").format("YYYY-MM-DD HH:mm:ss");
              const end = date
                ?.add(1, "day")
                .startOf("day")
                .format("YYYY-MM-DD HH:mm:ss");
              setDateStart(start);
              setDateEnd(end);
            }}
          />
        </Space>
      </div>
      {groupedTrips && Object.keys(groupedTrips).length > 0 ? (
        <Collapse>
          {Object.keys(groupedTrips).map((tripName) => {
            return (
              <Collapse.Panel
                header={
                  <div className="d-flex justify-content-between">
                    <span style={{ flexGrow: 1, width: "16.6%" }}>
                      <Typography.Text
                        strong
                        className="mb-0 d-block"
                        style={{ fontSize: 12 }}
                      >
                        Trip
                      </Typography.Text>
                      {tripName}
                    </span>
                    <span style={{ flexGrow: 1, width: "16.6%" }}>
                      <Typography.Text
                        strong
                        className="mb-0 d-block"
                        style={{ fontSize: 12 }}
                      >
                        Service
                      </Typography.Text>
                      {/* {groupedTrips[tripName]?.[0]?.service} */}
                      {Array.from(
                        new Set(
                          groupedTrips[tripName].map((x: any) => x?.service)
                        )
                      ).join(", ")}
                    </span>
                    <span style={{ flexGrow: 1, width: "16.6%" }}>
                      <Typography.Text
                        strong
                        className="mb-0 d-block"
                        style={{ fontSize: 12 }}
                      >
                        Assigned To
                      </Typography.Text>
                      {Array.from(
                        new Set(
                          groupedTrips[tripName].map(
                            (x: any) => x?.assigned_to?.f_name
                          )
                        )
                      )
                        .filter(Boolean)
                        .join(", ")}
                    </span>
                    <span style={{ flexGrow: 1, width: "16.6%" }}>
                      <Typography.Text
                        strong
                        className="mb-0 d-block"
                        style={{ fontSize: 12 }}
                      >
                        Delivery Person
                      </Typography.Text>
                      {Array.from(
                        new Set(
                          groupedTrips[tripName].map(
                            (x: any) => x?.delivery_person_name
                          )
                        )
                      )
                        .filter(Boolean)
                        .join(", ")}
                    </span>
                    <span style={{ flexGrow: 1, width: "16.6%" }}>
                      <Typography.Text
                        strong
                        className="mb-0 d-block"
                        style={{ fontSize: 12 }}
                      >
                        Next Pickup Planned ETA
                      </Typography.Text>
                      {nextPickupDelivery(tripName)
                        ? moment(
                            nextPickupDelivery(tripName).pickup_planned_eta
                          ).format("YYYY-MM-DD HH:mm:ss")
                        : "No more pickups"}{" "}
                      {nextPickupDelivery(tripName)
                        ? "(Δ " +
                          moment(
                            nextPickupDelivery(tripName).pickup_planned_eta
                          ).diff(currentTime, "minutes") +
                          ")"
                        : null}
                    </span>
                    {/* <span>
                    <Typography.Text
                      strong
                      className="mb-0 d-block"
                      style={{ fontSize: 12 }}
                    >
                      Last Pickup Actual Time
                    </Typography.Text>
                    {lastPickedUpDelivery
                      ? moment(lastPickedUpDelivery.pickup_time).format(
                          "YYYY-MM-DD HH:mm:ss"
                        )
                      : "No pickups yet"}
                  </span> */}
                    <span style={{ flexGrow: 1, width: "16.6%" }}>
                      <Typography.Text
                        strong
                        className="mb-0 d-block"
                        style={{ fontSize: 12 }}
                      >
                        Next Delivery Planned ETA
                      </Typography.Text>
                      {nextDelivery(tripName)
                        ? moment(
                            nextDelivery(tripName).delivery_planned_eta
                          ).format("YYYY-MM-DD HH:mm:ss")
                        : "No more deliveries"}{" "}
                      (Δ{" "}
                      {/* {nextDelivery && (
                          <>
                            {currentTime.isAfter(
                              moment(nextDelivery.delivery_planned_eta)
                            )
                              ? "+"
                              : "-"}
                          </>
                        )} */}
                      {nextDelivery(tripName) && (
                        <>
                          {moment(
                            nextDelivery(tripName).delivery_planned_eta
                          ).diff(currentTime, "minutes")}
                        </>
                      )}
                      )
                    </span>
                    {/* <span>
                    <Typography.Text
                      strong
                      className="mb-0 d-block"
                      style={{ fontSize: 12 }}
                    >
                      Last Delivery Actual Time
                    </Typography.Text>
                    {lastDeliveredDelivery
                      ? moment(lastDeliveredDelivery.delivery_time).format(
                          "YYYY-MM-DD HH:mm:ss"
                        )
                      : "No deliveries yet"}
                  </span> */}
                  </div>
                }
                key={tripName}
              >
                <Table
                  columns={columns}
                  dataSource={groupedTrips[tripName]?.sort((a: any, b: any) => {
                    return a.pickup_sequence - b.pickup_sequence;
                  })}
                  pagination={false}
                />
              </Collapse.Panel>
            );
          })}
        </Collapse>
      ) : (
        <Empty />
      )}
    </main>
  );
}
