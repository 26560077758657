import { Button, Form, FormInstance, Input, Select, Space } from "antd";
import React, { useEffect, useState } from "react";
import { editClient } from "../../../apis/client.api";

const { Option } = Select;
interface EditClientFormProps {
  client: any;
  onClientEditDrawerClose: Function;
  fetchBasicDetails: Function;
}
const EditClientForm = ({
  client,
  onClientEditDrawerClose,
  fetchBasicDetails,
}: EditClientFormProps) => {
  const [form] = Form.useForm();
  const [isStudio, setIsStudio] = useState(client?.roles === 8);
  const [loading, setLoading] = useState(false);
  const onFinish = (updatedValues: any) => {
    setLoading(true);
    console.log("updatedValues", updatedValues);
    editClient(updatedValues, client?.id).then((res) => {
      setLoading(false);
      onClientEditDrawerClose();
      fetchBasicDetails();
    });
  };

  const SubmitButton = ({ form }: { form: FormInstance }) => {
    const [submittable, setSubmittable] = React.useState(false);

    // Watch all values
    const values = Form.useWatch([], form);

    React.useEffect(() => {
      form.validateFields({ validateOnly: true }).then(
        () => {
          setSubmittable(true);
        },
        () => {
          setSubmittable(false);
        }
      );
    }, [values]);

    return (
      <Button
        type="primary"
        htmlType="submit"
        disabled={!submittable}
        loading={loading}
      >
        Submit
      </Button>
    );
  };

  useEffect(() => {
    Object.keys(client).forEach((key: any) => {
      form.setFieldValue(key, client[key]);
    });
  }, [client]);

  return (
    <Form
      form={form}
      name="validateOnly"
      layout="vertical"
      autoComplete="off"
      onFinish={onFinish}
    >
      <div className="pb-3 th-font-weight-500">
        <div>Phone</div>
        <div>{client?.contact}</div>
      </div>

      {/* <Form.List name="alternate_contact">
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map((field, index) => (
              <Form.Item
                label={index === 0 ? "Alternate phone" : ""}
                required={false}
                key={field.key}
              >
                <Form.Item
                  {...field}
                  validateTrigger={["onChange", "onBlur"]}
                  rules={[
                    {
                      pattern: /^[1-9]\d{9}$/gi,
                      message: "Enter a valid phone number",
                    },
                  ]}
                  noStyle
                >
                  <Input placeholder="Phone" style={{ width: "80%" }} />
                </Form.Item>
                {fields.length > 0 ? (
                  <MinusCircleOutlined
                    className="dynamic-delete-button mx-2"
                    onClick={() => remove(field.name)}
                  />
                ) : null}
              </Form.Item>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                style={{ width: "60%" }}
                icon={<PlusOutlined />}
              >
                Add alternate phone
              </Button>
              <Form.ErrorList errors={errors} />
            </Form.Item>
          </>
        )}
      </Form.List> */}
      <Form.Item
        rules={[
          {
            required: true,
            message: "Enter a valid salutation",
          },
        ]}
        name="salutation"
        label="Salutation"
      >
        <Select placeholder="Select salutation">
          <Option value="Mr">Mr</Option>
          <Option value="Mrs">Ms</Option>
          <Option value="Mx">Mx</Option>
        </Select>
      </Form.Item>
      <Form.Item
        required
        name="f_name"
        label="First Name"
        rules={
          [
            // {
            //   pattern: /^[a-zA-Z\s]*$/gi,
            //   message: "Enter a valid name",
            // }
          ]
        }
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="l_name"
        label="Last Name"
        rules={
          [
            // {
            //   pattern: /^[a-zA-Z\s]*$/gi,
            //   message: "Enter a valid name",
            // }
          ]
        }
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="email"
        label="Email"
        rules={[
          {
            type: "email",
            message: "Enter a valid email",
          },
        ]}
      >
        <Input />
      </Form.Item>

      {/* <Form.List name="alternate_email">
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map((field, index) => (
              <Form.Item
                label={index === 0 ? "Alternate email" : ""}
                required={false}
                key={field.key}
              >
                <Form.Item
                  {...field}
                  validateTrigger={["onChange", "onBlur"]}
                  noStyle
                >
                  <Input placeholder="Email" style={{ width: "80%" }} />
                </Form.Item>
                {fields.length > 0 ? (
                  <MinusCircleOutlined
                    className="dynamic-delete-button mx-2"
                    onClick={() => remove(field.name)}
                  />
                ) : null}
              </Form.Item>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                style={{ width: "60%" }}
                icon={<PlusOutlined />}
              >
                Add alternate email
              </Button>
              <Form.ErrorList errors={errors} />
            </Form.Item>
          </>
        )}
      </Form.List> */}
      {/* <Form.Item
        name="alternate_contact"
        label="Alternate phone"
        rules={[
          {
            pattern: /^[1-9]\d{9}$/gi,
            message: "Enter a valid phone number",
          },
        ]}
      >
        <Input type="number" maxLength={10} />
      </Form.Item>
      <Form.Item name="alternate_email" label="Alternate Email">
        <Input />
      </Form.Item> */}
      <Form.Item
        label="User Type"
        name="roles"
        rules={[
          {
            required: true,
            message: "Enter a valid user type",
          },
        ]}
      >
        <Select
          placeholder="Select user type"
          onChange={(val) => {
            if (val === 8) {
              setIsStudio(true);
            } else {
              setIsStudio(false);
            }
          }}
        >
          <Option value={10}>Public</Option>
          <Option value={8}>Studio</Option>
        </Select>
      </Form.Item>
      {
        <>
          <Form.Item
            label="GST Number"
            name="gst_number"
            rules={[
              {
                required: isStudio,
                pattern:
                  /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/g,
                message: "Enter a valid GST Number",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            required={isStudio}
            label="Company name"
            name="company_name"
          >
            <Input />
          </Form.Item>
          <Form.Item
            required={isStudio}
            label="Whatsapp group link"
            name="whatsapp_group_link"
            rules={[
              {
                type: "url",
                message: "Enter a valid URL",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </>
      }
      <Form.Item
        label="Source"
        name={"channel"}
        rules={[
          {
            required: true,
            message: "Enter a valid user source",
          },
        ]}
      >
        <Select placeholder="Select user source">
          <Option value="whatsapp">Whatsapp/DMs</Option>
          <Option value="call">Call</Option>
          <Option value="store_walk_in">Store Walk In</Option>
          <Option value="email">Email</Option>
          <Option value="website_orders">Website Orders</Option>
        </Select>
      </Form.Item>
      <Form.Item
        label="Medium"
        name={"medium"}
        rules={[
          {
            required: true,
            message: "Enter a valid user medium",
          },
        ]}
      >
        <Select placeholder="Select user medium">
          <Option value="Fbig">Fbig</Option>
          <Option value="SEO">SEO</Option>
          <Option value="SEM">SEM</Option>
          <Option value="SMM">SMM</Option>
          <Option value="Referral">Referral</Option>
          <Option value="Offline">Offline</Option>
          <Option value="Newsletter">Email Marketing/Newsletter</Option>
        </Select>
      </Form.Item>
      <Form.Item>
        <Space>
          <SubmitButton form={form} />
        </Space>
      </Form.Item>
    </Form>
  );
};

export default EditClientForm;
