import {
  Button,
  Drawer,
  Input,
  Pagination,
  Radio,
  Select,
  Space,
  Table,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import {
  cancelSlot,
  editSlot,
  fetchClientAddresses,
  fetchClients,
  getUserCartCount,
} from "../../apis/client.api";
import { fetchBookings, fetchUserDetails } from "../../apis/user.api";
import { CartSidebar } from "../../components/cart/cart_sidebar";
import CreateBookingForm from "../../components/client/add_client_booking_form";
import AddClientForm from "../../components/client/add_client_form";
import { BookingDetails } from "../../components/client/booking_details";
import { BookingRemarkForm } from "../../components/client/booking_remark_form";
import { BookingVisitDatePicker } from "../../components/client/booking_visit_date_picker";
import ClientPITable from "../../components/client/client_pi_table";
import { Footfall } from "../../components/client/footfall";
import { StatusDot } from "../../components/client/status_dot";
import { usePermissions } from "../../custom_hooks/permission/permission_hook";
import {
  getFormattedDate,
  getLeastRecentlyUpdatedCart,
} from "../../helpers/date_helper";
import { Client } from "../../interfaces/client.interface";

dayjs.extend(relativeTime);

const sortOptions = [
  { id: "1", value: "created_at", label: "Sort by Date Ascending" },
  { id: "2", value: "-created_at", label: "Sort by Date Descending" },
  // { id: "3", value: "name", label: "Sort by Name Ascending" },
  // { id: "4", value: "-name", label: "Sort by Name Descending" },
];

const cartSortOptions = [
  { id: "1", value: "latest_modified", label: "Sort by Date Ascending" },
  { id: "2", value: "-latest_modified", label: "Sort by Date Descending" },
  { id: "3", value: "total_price", label: "Sort by Price Ascending" },
  { id: "4", value: "-total_price", label: "Sort by Price Descending" },
];

export const booking_status_options = [
  { label: "Active", value: "active" },
  { label: "Cancelled", value: "cancelled" },
  { label: "Confirmed", value: "confirmed" },
  { label: "Visit Initiated", value: "visit_initiated" },
  { label: "Visit Completed", value: "visit_completed" },
];

const Clients = () => {
  const { hasPermission } = usePermissions();

  const [client_add_drawer_open, setClientAddDrawerOpen] = useState(false);
  const [client_pi_drawer_open, setClientPIDrawerOpen] = useState(false);
  const [visitDateDrawerOpen, setVisitDateDrawerOpen] = useState<
    "initiated" | "visited" | null
  >(null);
  const [client_address_drawer_open, setClientAddressDrawerOpen] =
    useState(false);
  const [create_booking_drawer_open, setCreateBookingDrawerOpen] =
    useState<boolean>(false);
  const [edit_booking_drawer_open, setEditBookingDrawerOpen] = useState<
    any | null
  >(null);
  const [bookingLoading, setBookingLoading] = useState(false);

  const [cart_drawer_open, setCartDrawerOpen] = useState<string | null>(null);
  const [total_clients, setTotalClients] = useState<number>(0);
  const [clients, setClients] = useState<Array<Client>>([]);
  const [page, setPage] = useState(1);
  const [bookingPage, setBookingPage] = useState(1);
  const [selected_client, setSelectedClient] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [client_address, setClientAddress] = useState([]);
  const [user_details, setUserDetails] = useState<any>(null);
  const [tab, setTab] = useState<string>("clients");
  const [bookings, setBookings] = useState<Array<any>>([]);
  const [booking_pages, setBookingPages] = useState<number>(0);
  const [cancellingLoading, setCancellingLoading] = useState<string>();
  const [bookingParams, setBookingParams] = useState<{
    booking_status?: string;
    search?: string;
    page?: number;
  }>({
    page: 1,
  });
  const [bookingSearch, setBookingSearch] = useState<string>("");
  const [cartFetchParams, setCartFetchParams] = useState({
    page: 1,
    contact: "",
  });

  const [sortVal, setSortVal] = useState<string>("-created_at");
  const [cartTotal, setCartTotal] = useState(0);
  const [cartData, setCartData] = useState([]);
  const [cartSortVal, setCartSortVal] = useState<string>("-latest_modified");
  const [bookingDetails, setBookingDetails] = useState<any>(null);
  const [footfallDrawerOpen, setFootfallDrawerOpen] = useState(false);

  const [bookingRemarksDrawer, setBookingRemarksDrawer] = useState<
    number | null
  >(null);
  const [bookingRemarksStatus, setBookingRemarksStatus] = useState<
    string | null
  >(null);
  const [bookingRemarksString, setBookingRemarksString] = useState<
    string | null
  >(null);

  const bookingStatusUpdateCallback = useRef<Function | null>(null);

  const cartColumns = useMemo(
    () => [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Contact",
        dataIndex: "contact",
        key: "contact",
      },
      {
        title: "Cart Count",
        dataIndex: "count",
        key: "count",
      },
      {
        title: "Total Price (approx.)",
        dataIndex: "total_price",
        key: "total_price",
        render: (total_price: number) => {
          return `₹${(total_price * 1.25).toFixed(1)}`;
        },
      },
      {
        title: "Modified",
        dataIndex: "latest_modified",
        key: "latest_modified",
        render: (latest_modified: string) => {
          return `${new Date(latest_modified).toDateString()} (${dayjs(
            latest_modified
          ).fromNow()})`;
        },
      },
      {
        title: "Actions",
        key: "actions",
        render: (record: any) => {
          return (
            <Space>
              <Link to={"/client/" + record?.user} target="_blank">
                <Button size="small" type="primary">
                  See Details
                </Button>
              </Link>
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  showCartDrawer(record?.user);
                }}
              >
                View Cart
              </Button>
            </Space>
          );
        },
      },
    ],
    []
  );

  const fetchData = useCallback(async () => {
    setLoading(true);
    const params = { ...cartFetchParams, order_by: cartSortVal } as Record<
      string,
      string | number
    >;
    if (params.contact === "") {
      delete params.contact;
    }
    const response = await getUserCartCount(params);
    setCartData(response.data ?? []);
    setCartTotal(response.count ?? 0);
    setLoading(false);
  }, [cartFetchParams, cartSortVal]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    fetchUserDetails().then((res) => {
      setUserDetails(res);
    });
  }, []);

  const getClients = (params: any) => {
    setLoading(true);
    if (params?.page) {
      setPage(params?.page);
    }
    fetchClients({ params: { page: params?.page ?? 1, ...params } }).then(
      (res) => {
        setLoading(false);
        setClients(res?.results ?? []);
        setTotalClients(res?.count ?? 0);
      }
    );
  };

  const getBookings = (params: any) => {
    setLoading(true);
    fetchBookings({
      params: {
        ...params,
        page: params?.page ?? 1,
        search: params?.search ?? bookingParams?.search ?? "",
        booking_status: params?.status ?? bookingParams?.booking_status ?? "",
      },
    }).then((res) => {
      setBookings(res?.results);
      setBookingPages(Math.floor(res?.count));
      setBookingPage(params?.page ?? 1);
      setLoading(false);
    });
  };

  useEffect(() => {
    getBookings(bookingParams);
  }, [bookingParams]);

  const cancelBooking = (booking_number: string) => {
    setCancellingLoading(booking_number);
    cancelSlot(booking_number).then((res) => {
      getBookings({
        page: bookingPage,
      });
      setCancellingLoading(undefined);
    });
  };

  useEffect(() => {
    if (tab == "clients") {
      getClients({ ordering: sortVal });
    } else {
      getBookings({});
    }
  }, [sortVal, tab]);

  useEffect(() => {
    const role = localStorage.getItem("user_role");
    if (user_details && role && role === "pre_sales") {
      setBookingSearch(user_details?.f_name);
      setBookingParams((prev) => ({
        ...prev,
        search: user_details?.f_name,
      }));
    }
  }, [user_details]);

  const showClientAddDrawer = () => {
    setClientAddDrawerOpen(true);
  };

  const onClientAddDrawerClose = () => {
    setClientAddDrawerOpen(false);
  };

  const onClientAddressDrawerClose = () => {
    setClientAddressDrawerOpen(false);
  };

  const onCreateBookingDrawerClose = () => {
    setCreateBookingDrawerOpen(false);
  };

  const showCartDrawer = (userId: string) => {
    setCartDrawerOpen(userId);
  };

  const onCartDrawerClose = () => {
    setCartDrawerOpen(null);
  };

  const updateBookingStatus = async (
    booking_id: number,
    status: string,
    record?: any
  ) => {
    const cb = async (params: { [k: string]: string } = {}) => {
      try {
        const response = await editSlot({
          id: booking_id,
          booking_status: status,
          ...params,
        });
        if (response) {
          const { remarks } = response;
          getBookings({});

          setBookingRemarksString(remarks);
          setBookingRemarksDrawer(booking_id);
          setBookingRemarksStatus(status);
        }
      } catch (e) {
        console.error(e);
      }
    };

    if (status === "confirmed" && record) {
      setEditBookingDrawerOpen(record);
      return;
    }

    if (status === "visit_initiated") {
      setVisitDateDrawerOpen("initiated");
      bookingStatusUpdateCallback.current = cb;
    } else if (status === "visit_completed") {
      setVisitDateDrawerOpen("visited");
      bookingStatusUpdateCallback.current = cb;
    } else {
      cb();
    }
  };

  const columns: ColumnsType<Client> = [
    {
      title: "Name",
      dataIndex: "f_name",
      key: "f_name",
    },
    {
      title: "Contact",
      dataIndex: "contact",
      key: "contact",
    },
    {
      title: "User type",
      dataIndex: "roles",
      key: "roles",

      render: (data) => (
        <div>{data == 8 ? "Studio" : data == 10 ? "Public" : ""}</div>
      ),
    },
    {
      title: "Created at",
      key: "created_at",
      render: (data) => <span>{getFormattedDate(data?.created_at)}</span>,
    },
    {
      title: "Cart Last Modified",
      key: "cart_last_modified",
      render: (data) => {
        return getLeastRecentlyUpdatedCart(data?.cart_user ?? []);
      },
    },
    {
      title: "Cart Items",
      key: "cart_items",
      render: (data) => {
        const items = data?.cart_user?.reduce((acc: number, item: any) => {
          return acc + parseInt(item?.quantity);
        }, 0);

        return items;
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space style={{ width: 500 }} size="middle">
          <Link to={"/client/" + record?.id} target="_blank">
            <Button
              size="small"
              type="primary"
              // onClick={() => {
              //   showClientEditDrawer();
              //   setSelectedClient(record);
              // }}
            >
              See Details
            </Button>
          </Link>
          {hasPermission("pi.create") && (
            <Link to={"/create-pro-forma-invoice/" + record?.id}>
              <Button size="small" type="primary">
                Create PI
              </Button>
            </Link>
          )}

          {hasPermission("pi.read") && (
            <Button
              size="small"
              type="primary"
              onClick={() => {
                setSelectedClient(record);
                setClientPIDrawerOpen(true);
              }}
            >
              See PIs
            </Button>
          )}
          <Button
            size="small"
            type="primary"
            onClick={() => {
              showCartDrawer(record?.id);
            }}
          >
            View Cart
          </Button>

          {/* {!record?.bnpl_active && (
            <>
              {record?.rupifi_activation_url?.length > 0 ? (
                <>BNPL:{record?.rupifi_activation_url}</>
              ) : (
                <Button
                  size="small"
                  type="primary"
                  onClick={() => {
                    fetchBnplActivationUrlForClient(record?.id).then(
                      (res) => {}
                    );
                  }}
                >
                  Generate BNPL Activation Url
                </Button>
              )}
            </>
          )} */}
        </Space>
      ),
    },
  ];
  const booking_columns: ColumnsType<any> = [
    {
      title: "Name",
      render: (record: any) => (
        <span className="d-inline-flex align-items-center gap-2">
          <StatusDot status={record?.booking_status} />
          {record?.user?.f_name ?? record?.name ?? ""}
        </span>
      ),
      key: "name",
    },
    {
      title: "Contact",
      dataIndex: "contact",
      key: "contact",
    },
    {
      title: "Visit date",
      key: "visit_date",
      render: (data) => (
        <span>
          {data?.visit_date ? new Date(data?.visit_date).toLocaleString() : ""}
        </span>
      ),
    },
    {
      title: "Assigned To",
      key: "assigned-to",
      render: (_, record) => <span>{record?.bda?.f_name}</span>,
    },
    {
      title: "Created at",
      key: "created_at",
      render: (data) => <span>{getFormattedDate(data?.created_at)}</span>,
    },
    {
      title: "Booking status",
      key: "booking_status",
      render: (data) => {
        return (
          <Select
            value={data?.booking_status}
            style={{ width: 150 }}
            onChange={(value) => {
              updateBookingStatus(data?.id, value, data);
            }}
          >
            {booking_status_options.map((item, index) => (
              <Select.Option
                value={item.value}
                key={item.value}
                disabled={
                  (data?.booking_status === "cancelled" &&
                    index >
                      booking_status_options.findIndex(
                        (item) => item.value === "cancelled"
                      )) ||
                  (data?.booking_status === "visit_completed" &&
                    index <
                      booking_status_options.findIndex(
                        (item) => item.value === "visit_completed"
                      ))
                }
              >
                {item.label}
              </Select.Option>
            ))}
          </Select>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space style={{ width: 200 }} size="middle">
          <Button
            size="small"
            onClick={() => {
              setBookingDetails(record);
            }}
          >
            View Details
          </Button>
          {record?.booking_status !== "cancelled" && (
            <Button
              size="small"
              type="primary"
              // onClick={() => {
              //   showClientEditDrawer();
              //   setSelectedClient(record);
              // }}
              onClick={() => {
                cancelBooking(`BK-${record?.id}`);
              }}
              loading={cancellingLoading === `BK-${record?.id}`}
            >
              Cancel
            </Button>
          )}
          {record?.user?.id && (
            <Button
              size="small"
              type="primary"
              onClick={() => {
                showCartDrawer(record?.user?.id);
              }}
            >
              View Cart
            </Button>
          )}
          {!record?.visit_date && record?.booking_status === "active" && (
            <Button
              size="small"
              type="primary"
              // onClick={() => {
              //   showClientEditDrawer();
              //   setSelectedClient(record);
              // }}
              onClick={() => {
                setEditBookingDrawerOpen(record);
              }}
            >
              Confirm Booking
            </Button>
          )}
        </Space>
      ),
    },
  ];

  const fetchClientAddress = (user_id: string) => {
    fetchClientAddresses(user_id).then((res) => {
      setClientAddress(res);
    });
  };

  const filterView = () => {
    if (tab === "bookings") {
      return (
        <Space>
          <Input.Search
            type="text"
            style={{ maxWidth: 180 }}
            placeholder="Search"
            value={bookingSearch}
            onChange={(e) => {
              setBookingSearch(e.target.value);
            }}
            onSearch={(value) => {
              setBookingParams({
                ...bookingParams,
                search: value,
                page: 1,
              });
            }}
          />
          <Select
            placeholder="Status"
            className="mt-0"
            style={{ minWidth: "200px" }}
            value={bookingParams?.booking_status}
            onChange={(value) => {
              setBookingParams({
                ...bookingParams,
                booking_status: value,
                page: 1,
              });
            }}
            allowClear
            options={booking_status_options}
          />
        </Space>
      );
    }
    if (tab === "cart") {
      return (
        <Space>
          <Select
            placeholder="Sort by"
            className="mt-0"
            style={{ minWidth: "200px" }}
            showArrow={true}
            value={cartSortVal}
            onChange={setCartSortVal}
            options={cartSortOptions.map((item: any) => ({
              value: item?.value,
              label: item?.label,
            }))}
          ></Select>
          <Input.Search
            placeholder="Search"
            onSearch={(value) =>
              setCartFetchParams({ ...cartFetchParams, contact: value })
            }
            style={{ width: 200 }}
          />
        </Space>
      );
    }
  };

  const table = () => {
    if (tab === "clients") {
      return (
        <>
          {" "}
          <Table
            columns={columns}
            dataSource={clients}
            pagination={false}
            loading={loading}
            rowKey={"id"}
            scroll={{
              x: "100%",
            }}
          />
          <Pagination
            current={page}
            total={total_clients ?? 1}
            showSizeChanger={false}
            pageSize={10}
            className="mt-3"
            onChange={(p) => {
              getClients({ page: p, search, ordering: sortVal });
            }}
          />
        </>
      );
    }
    if (tab === "bookings") {
      return (
        <>
          {" "}
          <Table
            columns={booking_columns}
            dataSource={bookings}
            pagination={false}
            loading={loading}
            rowKey={"id"}
            scroll={{
              x: "100%",
            }}
            onRow={(row) => {
              // @ts-ignore
              if (
                row.booking_status === "confirmed" &&
                // @ts-ignore
                new Date(row.visit_date) - new Date() < 0
              ) {
                return {
                  style: { backgroundColor: "#ffcccc" },
                };
              }

              return {};
            }}
          />
          <div className="d-flex align-items-center justify-content-between">
            <Pagination
              current={bookingPage}
              total={booking_pages}
              showSizeChanger={false}
              pageSize={30}
              className="mt-3"
              onChange={(p) => {
                setBookingParams({ ...bookingParams, page: p });
              }}
            />
            <Space size={"large"}>
              <div className="d-flex align-items-center">
                <StatusDot status="active" />
                Active
              </div>
              <div className="d-flex align-items-center">
                <StatusDot status="confirmed" />
                Confirmed, Visit Initiated, Visit Completed
              </div>
              <div className="d-flex align-items-center">
                <StatusDot status="cancelled" />
                Cancelled
              </div>
            </Space>
          </div>
        </>
      );
    }
    if (tab === "cart") {
      return (
        <>
          <Table
            columns={cartColumns}
            dataSource={cartData}
            pagination={false}
            loading={loading}
            className="mb-2"
          />
          <Pagination
            current={cartFetchParams.page}
            onChange={(page) =>
              setCartFetchParams({ ...cartFetchParams, page })
            }
            total={cartTotal}
            disabled={loading}
          />
        </>
      );
    }
  };

  return (
    <div className="mx-3 my-3 px-3 py-2 white-color-bg">
      <div>
        <div className="d-flex justify-content-between">
          <h3>Clients</h3>
          <div className="d-flex gap-2">
            {tab === "clients" && (
              <>
                <div>
                  <Select
                    placeholder="Sort by"
                    className="mt-0"
                    style={{ minWidth: "200px" }}
                    showArrow={true}
                    value={sortVal}
                    onChange={setSortVal}
                    options={sortOptions.map((item: any) => ({
                      value: item?.value,
                      label: item?.label,
                    }))}
                  ></Select>
                </div>
                <Input.Search
                  type="text"
                  style={{ maxWidth: 180 }}
                  placeholder="Search"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                  onSearch={(value) => {
                    setSortVal("-created_at");
                    getClients({ search: search });
                  }}
                />
              </>
            )}
            {hasPermission("client.create") && (
              <Button
                size="middle"
                className="fw-bold"
                type="primary"
                onClick={() => {
                  showClientAddDrawer();
                }}
              >
                Add Client
              </Button>
            )}
            {hasPermission("client.create") && (
              <Button
                size="middle"
                className="fw-bold"
                type="primary"
                onClick={() => {
                  setCreateBookingDrawerOpen(true);
                }}
              >
                Create Booking
              </Button>
            )}
            {hasPermission("client.create") && (
              <Button
                size="middle"
                className="fw-bold"
                type="primary"
                onClick={() => {
                  setFootfallDrawerOpen(true);
                }}
              >
                Add Footfall
              </Button>
            )}
          </div>
        </div>

        <div className="d-flex justify-content-between">
          <Radio.Group
            value={tab}
            onChange={(e) => {
              setTab(e.target.value);
            }}
            style={{ marginBottom: 16 }}
          >
            <Radio.Button value="clients">Clients</Radio.Button>
            <Radio.Button value="bookings">Bookings</Radio.Button>
            <Radio.Button value="cart">Cart</Radio.Button>
          </Radio.Group>
          {filterView()}
        </div>
        <div className="my-2">{table()}</div>
      </div>

      <Drawer
        title="Add Client"
        placement="right"
        closable={false}
        onClose={onClientAddDrawerClose}
        open={client_add_drawer_open}
      >
        <AddClientForm />
      </Drawer>
      <Drawer
        title={
          "Client PIs :" +
          (selected_client?.f_name ? selected_client?.f_name : "")
        }
        placement="right"
        closable={false}
        width={1200}
        onClose={() => {
          setClientPIDrawerOpen(false);
        }}
        open={client_pi_drawer_open}
      >
        <ClientPITable client_data={selected_client} />
      </Drawer>

      <Drawer
        title="Client Addresses"
        placement="right"
        width={500}
        closable={false}
        onClose={onClientAddressDrawerClose}
        open={client_address_drawer_open}
      >
        <Button type="primary" className="sticky-bottom w-100">
          <div className="fw-bold">Create new address</div>{" "}
        </Button>
      </Drawer>

      <Drawer
        title="Create Booking"
        placement="right"
        closable={false}
        onClose={onCreateBookingDrawerClose}
        open={create_booking_drawer_open}
      >
        <CreateBookingForm
          onCreateBookingDrawerClose={onCreateBookingDrawerClose}
          getBookings={getBookings}
        />
      </Drawer>
      <Drawer
        title="Edit Booking"
        placement="right"
        closable={false}
        onClose={() => setEditBookingDrawerOpen(null)}
        open={Boolean(edit_booking_drawer_open)}
      >
        <CreateBookingForm
          editMode
          onCreateBookingDrawerClose={(response) => {
            setEditBookingDrawerOpen(null);
            setBookingRemarksString(response?.remarks);
            setBookingRemarksDrawer(response?.id);
            setBookingRemarksStatus(response?.booking_status);
          }}
          getBookings={getBookings}
          bookingDetails={edit_booking_drawer_open}
        />
      </Drawer>
      <Drawer
        title="Client Cart"
        placement="right"
        closable={false}
        onClose={onCartDrawerClose}
        open={Boolean(cart_drawer_open)}
        width={700}
      >
        {cart_drawer_open && (
          <CartSidebar userId={cart_drawer_open} onClose={onCartDrawerClose} />
        )}
      </Drawer>

      <Drawer
        title="Visit Date"
        placement="right"
        closable={false}
        onClose={() => {}}
        open={Boolean(visitDateDrawerOpen)}
      >
        <BookingVisitDatePicker
          type={visitDateDrawerOpen}
          onFinish={(params: { [k: string]: string }) => {
            if (bookingStatusUpdateCallback.current) {
              bookingStatusUpdateCallback.current(params);
              setVisitDateDrawerOpen(null);
            }
          }}
        />
      </Drawer>
      <Drawer
        title="Client Details"
        placement="right"
        onClose={() => setBookingDetails(null)}
        open={Boolean(bookingDetails)}
      >
        <BookingDetails details={bookingDetails} />
      </Drawer>
      <Drawer
        title="Booking Remarks"
        placement="right"
        closable={false}
        onClose={() => {}}
        open={Boolean(bookingRemarksDrawer)}
      >
        {bookingRemarksDrawer && bookingRemarksStatus && (
          <BookingRemarkForm
            id={bookingRemarksDrawer}
            status={bookingRemarksStatus}
            remarks={bookingRemarksString}
            onFinish={() => {
              setBookingRemarksDrawer(null);
              setBookingRemarksStatus(null);
              setBookingRemarksString(null);

              getBookings({});
            }}
          />
        )}
      </Drawer>
      <Drawer
        title="Add Footfall"
        placement="right"
        closable={false}
        onClose={() => setFootfallDrawerOpen(false)}
        open={footfallDrawerOpen}
      >
        <Footfall
          onClose={() => {
            setFootfallDrawerOpen(false);
          }}
        />
      </Drawer>
    </div>
  );
};

export default Clients;
