import { message } from "antd";
import axios from "axios";

const labels: any = {
  "category.category_name": "Category",
  "category.category_unit": "Category Unit",
  "brand.brand_name": "Brand",
  "color.color_family": "Color",
  approx_size: "Approx Size",
  finish: "Finish",
  base_material: "Base Material",
  approx_thickness: "Approx Thickness",
  sub_category: "Sub Category",
};

export function getLabel(key: any) {
  if (labels[key]) {
    return labels[key];
  }
  return key;
}

export async function pushToOpenSearch(variant_handle: string) {
  try {
    return axios.get(
      `apiV1/product-to-open-search/?variant_handle=${encodeURIComponent(
        variant_handle
      )}`
    );
  } catch (error) {
    console.log("i-log> error", error);
    message.error("Something went wrong while pushing to open search");
  }
}

export async function fetchOpenSearchProducts(
  searchText: string,
  fieldsToSearch: any = {},
  pageNumber = 1,
  pageSize = 30,
  sort = "relevance",
  userType = "studio",
  mdView = true
) {
  const fields: any = {};
  Object.keys(fieldsToSearch).forEach((key: string) => {
    if (fieldsToSearch[key]?.length > 0) {
      fields[key.replace("variants.", "")] = fieldsToSearch[key];
    }
  });

  try {
    const request: any = {
      query_string: searchText?.replaceAll("/", "-"),
      fields_to_search: fields,
      page_number: pageNumber,
      page_size: pageSize,
      sort_by: sort,
      price_availability: true,
      md: mdView,
    };

    if (userType !== null) {
      request["user_type"] = userType;
    }

    const response = await fetch(
      "https://api-dev2.materialdepot.in/apiV1/product-search-v2/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(request),
      }
    );

    return response.json();
  } catch (error) {
    console.log("i-log> error", error);
    message.error("Something went wrong while fetching products");
    return null;
  }
}

export async function fetchProductDetails(variant_handle: string) {
  try {
    const response = await fetch(
      `https://api-dev2.materialdepot.in/apiV1/product-details-v2/?variant_handle=${encodeURIComponent(
        variant_handle
      )}`
    );

    return response.json();
  } catch (error) {
    message.error("Something went wrong while fetching product details");
    return null;
  }
}

export async function searchProductsElasticSearch(
  searchText: string,
  fieldsToSearch: any = {},
  pageNumber = 1,
  pageSize = 30,
  sort = "relevance",
  userType = "studio",
  mdView = true
): Promise<any> {
  const fields: any = {};
  Object.keys(fieldsToSearch).forEach((key: string) => {
    if (fieldsToSearch[key]?.length > 0) {
      fields[key.replace("variants.", "")] = fieldsToSearch[key];
    }
  });
  try {
    const request: any = {
      query_string: searchText?.replaceAll("/", "-"),
      fields_to_search: fields,
      page_number: parseInt(String(pageNumber ?? 0)) + 1,
      page_size: pageSize,
      sort_by: sort,
      price_availability: true,
      md: mdView,
    };
    let formatted_data = { ...request };
    if (formatted_data.fields_to_search?._id) {
      formatted_data.fields_to_search = {
        ...formatted_data.fields_to_search,
        variant_id: formatted_data.fields_to_search._id,
      };
      delete formatted_data.fields_to_search._id;
    }
    let res = await axios
      .post(
        "https://api-dev2.materialdepot.in/apiV1/product_search_with_global_aggregations/",
        {
          ...formatted_data,
        }
      )
      .then((response) => {
        return response?.data;
      });
    return res;
  } catch (error) {
    return null;
  }
}
