import { Button, Form, Input, message, Select } from "antd";
import { useState } from "react";
import { createDelivery } from "../../../apis/misc.api";
import { createPidgeOrder, editPurchaseOrder } from "../../../apis/po.api";

export interface AttachDeliveryProps {
  poId: number;
  onClose: () => void;
}

export function AttachDelivery({ poId, onClose }: AttachDeliveryProps) {
  const [form] = Form.useForm();
  const [isPorter, setIsPorter] = useState<boolean>(false);
  const [isPidge, setIsPidge] = useState<boolean>(false);

  const onSubmit = async (values: any) => {
    if (isPidge) {
      message.loading({
        content: "Attaching delivery...",
        key: "attachDelivery",
        duration: 0,
      });
      await createPidgeOrder(poId);
      message.success({
        content: "Delivery attached successfully",
        key: "attachDelivery",
      });
      return;
    }

    if (isPorter) {
      // Get Link and update that to the PO
      const { live_location_link } = values;
      if (!live_location_link) {
        message.error("Please enter live location link");
        return;
      }

      const response = await editPurchaseOrder(poId, {
        live_location_link,
      });

      delete values.live_location_link;
    }

    const payload = {
      ...values,
      last_updated_status: "created",
      po_id: poId,
    };

    await createDelivery(payload);

    message.success("Delivery attached successfully");

    onClose();
  };

  return (
    <Form layout="vertical" form={form} onFinish={onSubmit}>
      <Form.Item
        label="Service"
        name="service"
        rules={[
          {
            required: true,
            message: "Please select a service",
          },
        ]}
      >
        <Select
          placeholder="Select a service"
          onChange={() => {
            setIsPorter(form.getFieldValue("service") === "porter");
            setIsPidge(form.getFieldValue("service") === "pidge");
          }}
        >
          <Select.Option value="porter">Porter</Select.Option>
          <Select.Option value="pidge">Pidge</Select.Option>
          <Select.Option value="courier">Courier</Select.Option>
        </Select>
      </Form.Item>
      {!isPidge && (
        <Form.Item
          label="Service Reference"
          name="service_ref_id"
          rules={[
            {
              required: true,
              message: "Please enter service reference",
            },
          ]}
        >
          <Input placeholder="Enter service reference" />
        </Form.Item>
      )}
      {isPorter && !isPidge && (
        <Form.Item
          name="live_location_link"
          label="Live Location Link"
          rules={[
            {
              required: true,
              message: "Please enter live location link",
            },
            {
              type: "url",
              message: "Please enter a valid URL",
            },
          ]}
        >
          <Input placeholder="Enter live location link" />
        </Form.Item>
      )}
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Attach Delivery
        </Button>
      </Form.Item>
    </Form>
  );
}
