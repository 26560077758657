import { Button, Collapse, message } from "antd";
import { useRef, useState } from "react";
import { createLoginextOrder } from "../../../apis/po.api";

interface Row {
  po_number: string;
  depot?: string;
  dispatch_date?: string;
}

export function LoginextCSVUpload({ onClose }: { onClose: () => void }) {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [rows, setRows] = useState<Row[]>([]);
  const [processing, setProcessing] = useState(false);
  const [results, setResults] = useState<{ [po_number: string]: string }>({});

  const readCSVFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const csv = e.target?.result;
        if (csv && typeof csv === "string") {
          const data = csv.split("\n").map((row) => row.trim().split(","));
          const headers = data[0];

          const rows = data.slice(1).map((row) => {
            const obj: { [key: string]: string } = {};
            row.forEach((value, i) => {
              if (value.length > 0) {
                obj[headers[i]] = value;
              }
            });
            return obj;
          });

          // Make sure that po_number is present in all rows and is not empty
          if (rows.some((row) => !row.po_number || !row.po_number.trim())) {
            message.error("All rows must have a non-empty 'po_number' field");
            return;
          }

          setRows(rows as unknown as Row[]);
        }
      };
      reader.readAsText(file);
    }
  };

  const startProcessing = async () => {
    setProcessing(true);

    // Serially process each row
    const processRow = async (row: Row) => {
      const result = await createLoginextOrder(row);

      setResults((prev) => ({ ...prev, [row.po_number]: result }));
    };

    for (const row of rows) {
      await processRow(row);
    }

    setProcessing(false);
  };

  return (
    <div>
      <input
        type="file"
        style={{ width: 1, height: 1, overflow: "hidden", opacity: 0 }}
        accept=".csv"
        onChange={readCSVFile}
        ref={fileInputRef}
      />
      <Button
        onClick={() => fileInputRef.current?.click()}
        disabled={processing}
        className="me-3"
      >
        Upload CSV
      </Button>
      <Button onClick={startProcessing} disabled={processing}>
        {processing ? "Processing..." : "Start Processing"}
      </Button>
      <Collapse
        style={{ marginTop: 16 }}
        items={rows.map((row) => ({
          key: row.po_number,
          label: row.po_number,
          children: (
            <div>
              <div>Depot: {row.depot || <i>Empty Value</i>}</div>
              <div>
                Dispatch Date: {row.dispatch_date || <i>Empty Value</i>}
              </div>
              {results[row.po_number] ? (
                <pre>{JSON.stringify(results[row.po_number], null, 2)}</pre>
              ) : (
                <div>No result yet</div>
              )}
            </div>
          ),
        }))}
      />
    </div>
  );
}
