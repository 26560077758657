import { Button, Collapse, Input, message, Pagination, Tag } from "antd";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { importLoginextTrip, pushTripToPidge } from "../../../apis/misc.api";
import { getLoginextTrips } from "../../../apis/po.api";

export interface ILoginextTrip {
  id: number;
  trip_name: string;
  trip_details_json: string;
  is_deleted: boolean;
  created_at: string;
  modified_at: string;
  sequence: {
    sequence: number;
    type: "pickup" | "delivery";
    orderNo: string;
  }[];
}

export function LoginextTrip() {
  const [trips, setTrips] = useState<ILoginextTrip[]>([]);
  const [count, setCount] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>("");

  const fetchTrips = useCallback(async () => {
    const response = await getLoginextTrips({ page, search });
    if (response) {
      const { results, count } = response;
      const tripsWithSequence = results.map((trip: ILoginextTrip) => {
        const tripDetails = JSON.parse(trip.trip_details_json);
        const allOrdersBothLegs = [] as Array<any>;
        tripDetails.orderDetails.forEach((orderDetail: any) => {
          const { pickupDetails, deliverDetails } = orderDetail;
          allOrdersBothLegs.push({
            ...pickupDetails[0],
            orderNo: orderDetail.orderNo,
            type: "pickup",
          });
          allOrdersBothLegs.push({
            ...deliverDetails[0],
            orderNo: orderDetail.orderNo,
            type: "delivery",
          });
        });

        const sortedOrders = allOrdersBothLegs.sort(
          (a, b) => a.sequence - b.sequence
        );

        return {
          ...trip,
          sequence: sortedOrders,
        };
      });

      setTrips(tripsWithSequence);
      setCount(count);
    }
  }, [page, search]);

  useEffect(() => {
    fetchTrips();
  }, [fetchTrips]);

  const assignTrip = useCallback(
    (tripId: number) => async () => {
      message.loading({
        content: "Assigning trip to Pidge",
        key: "assigningTrip",
      });
      await pushTripToPidge(tripId);
      message.success({
        content: "Trip assigned to Pidge",
        key: "assigningTrip",
      });
    },
    []
  );

  const reImportTrip = useCallback(
    (tripName: string) => async () => {
      message.loading({
        content: "Re-importing trip",
        key: "reImportTrip",
      });
      await importLoginextTrip([tripName]);
      message.success({
        content: "Trip re-imported. Reload to view changes.",
        key: "reImportTrip",
      });
    },
    []
  );

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <div>{count} trips found</div>
        <Input.Search
          placeholder="Search trips"
          onSearch={(value) => setSearch(value)}
          allowClear
          style={{ width: 200 }}
        />
      </div>
      <Collapse>
        {trips.map((trip) => (
          <Collapse.Panel
            header={
              <div className="d-flex justify-content-between">
                <span>{trip.trip_name}</span>
                <span>
                  {moment(trip?.created_at)?.format("DD-MM-YYYY HH:mm")}
                </span>
              </div>
            }
            key={trip.trip_name}
          >
            <ul>
              {trip?.sequence?.map((detail, index: number) => (
                <li key={index}>
                  {detail.orderNo}{" "}
                  <Tag color={detail.type === "pickup" ? "blue" : "green"}>
                    {detail.sequence}. {detail.type}
                  </Tag>
                </li>
              ))}
            </ul>
            <Button.Group>
              <Button type="primary" onClick={assignTrip(trip?.id)}>
                Assign to Pidge
              </Button>
              <Button type="primary" disabled>
                Assign to Loginext
              </Button>
              <Button onClick={reImportTrip(trip?.trip_name)}>
                Re-Import Trip
              </Button>
            </Button.Group>
          </Collapse.Panel>
        ))}
      </Collapse>

      <Pagination
        current={page}
        total={count}
        onChange={(page) => setPage(page)}
        showSizeChanger={false}
        className="mt-4"
      />
    </div>
  );
}
