import { Divider } from "antd";
import { getFormattedDateAndTime } from "../../../helpers/date_helper";
import { getPIStatusLabel } from "../../../helpers/misc_helper";

interface ProFormaLogCardProps {
  log: any;
}

const ProFormaLogCard = ({ log }: ProFormaLogCardProps) => {
  return (
    <>
      <div className="d-flex row">
        <div className="col-4">
          <div> Action at :</div>
        </div>
        <div className="col-8">
          <b> {getFormattedDateAndTime(log?.created_at)}</b>
        </div>
      </div>
      <div className="d-flex row mt-1">
        <div className="col-4"> Action by :</div>
        <div className="col-8">
          <b>{log?.updated_by?.f_name ?? "System Generated"}</b>
        </div>
      </div>
      <div className="d-flex row mt-1">
        <div className="col-4"> Status :</div>
        <div className="col-8">
          <b className="text-primary">{getPIStatusLabel(log?.status)}</b>
        </div>
      </div>
      <div className="d-flex row mt-1">
        <div className="col-4">Estimate ID:</div>
        <div className="col-8">
          <b># {log?.estimate}</b>
        </div>
      </div>
      <Divider />
    </>
  );
};

export default ProFormaLogCard;
