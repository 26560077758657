import { FilterOutlined } from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Drawer,
  Input,
  Modal,
  Pagination,
  Popconfirm,
  Radio,
  Select,
  Space,
  Table,
  Tag,
  message,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import {
  createLoginextOrders,
  editPurchaseOrder,
  exportPOCSV,
  getPurchaseOrders,
  rocketBoxShipmentCreation,
} from "../../../apis/po.api";
import { getFormattedDateAndTime } from "../../../helpers/date_helper";

import { fetchBranchMembers } from "../../../apis/branch.api";
import { getPurchaseOrderDetails } from "../../../apis/po.api";

import moment from "moment";
import { fetchBranches } from "../../../apis/branch.api";
import { fetchClientDetails } from "../../../apis/client.api";
import { editDelivery, getDeliveries } from "../../../apis/misc.api";
import { PowoDeliveryChallanOpenApi } from "../../../apis/routes";
import { fetchUserDetails } from "../../../apis/user.api";
import { fetchVendors } from "../../../apis/vendor.api";
import { AttachDelivery } from "../../../components/delivery/attach_delivery";
import DeliveryLocationDrawer from "../../../components/delivery/delivery_location_drawer";
import EWayBillDrawer from "../../../components/delivery/eway_bill_drawer";
import { LoginextCSVUpload } from "../../../components/delivery/loginext_csv_upload";
import { LoginextTrip } from "../../../components/po/loginext_trip";
import DetailsPreview from "../../../components/po/po_details_modal";
import { usePermissions } from "../../../custom_hooks/permission/permission_hook";
import { Client } from "../../../interfaces/client.interface";
import { TrackerByDeliveries } from "./tracker/by_deliveries";

const { RangePicker } = DatePicker;

export interface DataType {
  id: number;
  created_by: string;
  type_of_order: string;
  delivery_date: string;
  organisation: number;
  project: Project;
  vendor: Vendor;
  terms_and_condition: TermsAndCondition;
  total_po_raised_amount: number;
  created_at: string;
  modified_at: string;
  po_payment_terms: PoPaymentTerm[];
  documents: any[];
  terms_and_conditions_description: string;
  is_deleted: boolean;
  deleted_by: any;
  gst: Gst;
  address: Address;
  address_description: string;
  migration_id: any;
  is_approved: boolean;
  is_pending: boolean;
  is_decline: boolean;
  approval_flow: any[];
  po_items: PoItem[];
  po_additional_charges: any[];
  po_number: string;
  old_attachment: any;
  lead_id: string;
}

export interface Project {
  id: number;
  project_name: string;
  project_code: any;
  project_address: string;
  pincode: string;
  city: string;
  contact_phone: number;
  contact_email: string;
  contact_person: string;
  start_date: string;
  end_date: string;
  migration_id: any;
  created_at: string;
  project_created_at: string;
  modified_at: string;
  is_archived: boolean;
  is_deleted: boolean;
  organisation: number;
  branch: number;
  currency: number;
  project_created_by: string;
  client: any;
}

export interface Vendor {
  id: number;
  name: string;
  vendor_code: string;
  contact: string;
  vendor_type: any;
  email: string;
  contact_person: string;
  comment: any;
  designation: any;
  pan_number: string;
  gst: string;
  bank_account_holder: string;
  bank_account_number: string;
  bank_ifsc: string;
  billing_address: string;
  pincode: string;
  city: any;
  optional_contact1: any;
  optional_contact_name1: any;
  optional_contact2: any;
  optional_contact_name2: any;
  optional_email1: any;
  optional_email2: any;
  map1: any;
  map2: any;
  map3: any;
  is_verified: boolean;
  status: boolean;
  organisation: number;
  branch: number;
  category: number[];
  brand: any[];
}

export interface TermsAndCondition {
  id: number;
  title: string;
  description: string;
  status: boolean;
  organisation: number;
}

export interface PoPaymentTerm {
  id: number;
  term: string;
  percentage: number;
  deadline: string;
  is_deleted: boolean;
  po: number;
  deleted_by: any;
}

export interface Gst {
  id: number;
  label: string;
  city: string;
  pincode: string;
  address: string;
  gst_no: string;
  contact: string;
  email: string;
  pan_no: string;
  organisation: any;
  branch: number;
}

export interface Address {
  id: number;
  label: string;
  city: string;
  pincode: string;
  contact_person: string;
  contact_phone: number;
  contact_email: string;
  address: string;
  google_map_address: string;
  gst_no: string;
  pan_no: string;
  project: number;
}

export interface PoItem {
  id: number;
  item: Item;
  sub_item: any;
  unit_cost_price: number;
  unit_selling_price: number;
  unit_po_price: number;
  total_price: number;
  quantity: number;
  discount: number;
  tax_value: number;
  tax_type: string;
  is_deleted: boolean;
  po: number;
  deleted_by: any;
}

export interface Item {
  id: number;
  boq_item: string;
  item_description: string;
  item_image: any;
  unit: string;
  quantity: number;
  brand: string;
  identifier: string;
  lead_id: string;
  budget_price: number;
  boq_price: number;
  tax_value: number;
  tax_type: string;
  timeline: any;
  remark: any;
  status: boolean;
  is_deleted: boolean;
  is_selected: boolean;
  project: number;
  category_name: number;
  assigned_to: any;
}

const sortOptions = [
  { id: "1", value: "dispatch_time", label: "Sort by Dispatch time Ascending" },
  {
    id: "2",
    value: "-dispatch_time",
    label: "Sort by Dispatch time  Descending",
  },
  {
    id: "3",
    value: "total_po_raised_amount",
    label: "Sort by Total Ascending",
  },
  {
    id: "4",
    value: "-total_po_raised_amount",
    label: "Sort by Total Descending",
  },
  // removed delivery_by as it is not in ordering fields
  // { id: "5", value: "delivery_by", label: "Delivery Date Ascending" },
  // { id: "6", value: "-delivery_by", label: "Delivery Date Descending" },
];
const status_type: Array<any> = [
  // {
  //   id: 1,
  //   label: "Order Raised",
  //   value: "order_raised",
  // },
  {
    id: 2,
    label: "Dispatch Pending",
    value: "dispatch_pending",
  },
  {
    id: 99,
    label: "Delivery Assigned",
    value: "delivery_assigned",
  },
  {
    id: 5,
    label: "In Store",
    value: "in_store",
  },
  {
    id: 3,
    label: "Dispatched",
    value: "dispatched",
  },
  {
    id: 4,
    label: "Delivered",
    value: "delivered",
  },
];
const { Option } = Select;

const DeliveryList = () => {
  const { hasPermission } = usePermissions();

  const [data, setData] = useState<Array<any>>([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [total_pos, setTotalPos] = useState<number>(0);
  const [search, setSearch] = useState("");
  const [po_data, setPOData] = useState<any>(null);

  const [members, setMembers] = useState([]);
  const [tab_type, setTabType] = useState("dispatch_pending,delivery_assigned");
  const [status, setStatus] = useState<string>();
  // const [po_status, setPo_status] = useState<string>();
  const [created_at__gte, setCreated_at__gte] = useState<string>();
  const [created_at__lte, setCreated_at__lte] = useState<string>();
  const [dispatch_time__gte, setDispatchTime__gte] = useState<string>();
  const [dispatch_time__lte, setDispatchTime__lte] = useState<string>();
  const [created_by, setCreated_by] = useState<string>();
  const [vendor_name, setVendor_name] = useState<string>();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [tripsDrawerVisible, setTripsDrawerVisible] = useState(false);
  const [deliveriesView, setDeliveriesView] = useState(false);
  const [etasView, setEtasView] = useState(false);
  const [attachDeliveryDrawerVisible, setAttachDeliveryDrawerVisible] =
    useState<number | null>(null);

  const [showLoginextCSVUpload, setShowLoginextCSVUpload] = useState(false);

  const [currentUser, setCurrentUser] = useState<Record<
    string,
    unknown
  > | null>(null);

  const handleButtonClick = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleReset = () => {
    setCreated_by(undefined);
    setCreated_at__gte(undefined);
    setCreated_at__lte(undefined);
    setVendor_name(undefined);
    setStatus(undefined);
    setDispatchTime__gte(undefined);
    setDispatchTime__lte(undefined);
    // setPo_status(undefined);
  };

  const [data1, setData1] = useState<any>({});
  const [branch_data, setBranchData] = useState<any>({});
  const [client_data, setClientData] = useState<Client | null>(null);
  const [vendors, setVendors] = useState<Array<any>>([]);
  const [vendor_id, setVendorId] = useState<any>(null);

  const [delivery_location_drawer_visible, setDeliveryLocationDrawerVisible] =
    useState(false);
  const [deliveries, setDeliveries] = useState<any[]>([]);
  const [deliveriesCount, setDeliveriesCount] = useState<number>(0);
  const [deliveriesPage, setDeliveriesPage] = useState<number>(1);

  const [eway_bill_drawer_visible, setEwayBillDrawerVisible] = useState(false);

  const [basicModal, setBasicModal] = useState(false);
  const [id1, setId1] = useState("");
  const toggleShow = (id: any) => {
    setBasicModal(!basicModal);
  };

  const [sortVal, setSortVal] = useState<string>();

  const getVendors = useCallback(() => {
    fetchVendors({ search }).then((res) => {
      setVendors(res);
      setLoading(false);
    });
  }, []);

  const fetchDeliveries = async ({
    page = 1,
    search = "",
  }: {
    page: number;
    search?: string;
  }) => {
    setLoading(true);
    setPage(page);
    await getDeliveries({ page, search }).then((res) => {
      setDeliveries(res?.results);
      setDeliveriesCount(res?.count);
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchDeliveries({ page: deliveriesPage });
  }, [deliveriesPage]);

  const deliveriesColumns = [
    {
      title: "PO No.",
      key: "po_number",
      render: (data: any) => data?.po?.po_number,
    },
    {
      title: "Service",
      key: "service",
      dataIndex: "service",
    },
    {
      title: "Service Ref ID",
      key: "service_ref_id",
      dataIndex: "service_ref_id",
    },
    {
      title: "Last Updated Status",
      key: "last_updated_status",
      render: (data: any) => {
        if (data?.is_deleted) {
          return "Deleted";
        }

        return (
          <Select
            value={data?.last_updated_status}
            style={{ width: 200 }}
            onChange={(value) => {
              editDelivery({
                id: data?.id,
                last_updated_status: value,
              });

              fetchDeliveries({ page: 1 });
            }}
            disabled={["push_error", "delivered"].includes(
              data?.last_updated_status
            )}
          >
            <Option value="created">Created</Option>
            <Option value="in_store">In Store</Option>
            <Option value="picked_up">Picked Up</Option>
            <Option value="in_transit">In Transit</Option>
            <Option value="delivered">Delivered</Option>
            <Option value="push_error">Push Error</Option>
          </Select>
        );
      },
    },
    {
      title: "Created At",
      key: "created_at",
      render: (data: any) => getFormattedDateAndTime(data?.created_at),
    },
    {
      title: "Actions",
      key: "actions",
      render: (data: any) => (
        <Space>
          <Popconfirm
            onConfirm={() => {
              editDelivery({
                id: data?.id,
                is_deleted: true,
              });

              fetchDeliveries({ page: 1 });
            }}
            title="Are you sure you want to delete this delivery?"
          >
            <Button size="small" danger>
              Cancel Delivery
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const columns: ColumnsType<DataType> = [
    {
      title: "Po No.",
      key: "po_number",
      render: (data) => {
        const transport = data?.estimate?.estimate_additional_charges?.find(
          (charge: any) => {
            return (
              charge?.amount > 0 && charge?.charge_type === "Transport Charges"
            );
          }
        );
        const isExpress = transport
          ? transport?.remark?.toLowerCase()?.includes("express")
          : false;
        const unloading = data?.estimate?.estimate_additional_charges?.find(
          (charge: any) => {
            return (
              charge?.amount > 0 && charge?.charge_type === "Unloading Charges"
            );
          }
        );
        return (
          <div>
            <span style={{ minWidth: 150 }}>{data?.po_number}</span>
            {data?.estimate?.is_outside_delivery && (
              <Tag color="orange" className="mt-1">
                Outstation Delivery
              </Tag>
            )}{" "}
            {data?.estimate?.is_store_pickup && (
              <Tag color="green" className="mt-1">
                Store pickup
              </Tag>
            )}
            {isExpress && (
              <Tag color="blue" className="mt-1">
                Express
              </Tag>
            )}
            {unloading && (
              <Tag color="cyan" className="mt-1">
                Unloading
              </Tag>
            )}
          </div>
        );
      },
    },
    {
      title: "Lead ID.",
      key: "lead_id",
      width: 110,
      render: (data) => <div>{data?.estimate?.lead_id}</div>,
    },
    {
      title: "Vendor Name",
      key: "vendor",
      render: (data) => <div style={{ width: 170 }}>{data?.vendor?.name}</div>,
    },
    {
      title: "Dispatch time ",
      key: "dispatch_time",
      render: (data) => (
        <div style={{ width: 160 }}>
          {getFormattedDateAndTime(data?.dispatch_time)}
        </div>
      ),
    },
    {
      title: "Delivery Attached",
      key: "delivery_attached",
      render: (data: any) => {
        const info = data?.po_delivery_info;
        if (!info || info?.length === 0) {
          return "N/A";
        } else {
          const delivery = info[info.length - 1];
          return (
            <div style={{ width: 120 }}>
              <div>{delivery?.service}</div>
              {/* <div
                style={{
                  width: 100,
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              >
                {delivery?.service_ref_id}
              </div> */}
            </div>
          );
        }
      },
    },
    {
      title: "Delivery link",
      key: "live_location_link",
      render: (data) => (
        <div style={{ width: 160 }}>
          {data?.live_location_link ? (
            <Tag
              className="cursor-pointer"
              onClick={() => {
                window.open(
                  data?.live_location_link?.includes("https://")
                    ? data?.live_location_link
                    : "https://" + data?.live_location_link,
                  "_blank"
                );
              }}
              color="blue"
            >
              Location link
            </Tag>
          ) : (
            <></>
          )}
        </div>
      ),
    },
    {
      title: "Delivery link time",
      key: "location_link_entry_time",
      render: (data) => (
        <div style={{ width: 160 }}>
          {getFormattedDateAndTime(data?.location_link_entry_time)}
        </div>
      ),
    },

    {
      title: "PI Assigned to",
      key: "assigned-to",
      render: (data) => (
        <div style={{ width: 110 }}>{data?.estimate?.assigned_to?.f_name}</div>
      ),
    },
    {
      title: "PI Created by",
      key: "ppi-created-by",
      render: (data) => (
        <div style={{ width: 110 }}>{data?.estimate?.created_by}</div>
      ),
    },
    {
      title: "PO Created by",
      key: "created_by",
      render: (data) => <div style={{ width: 110 }}>{data?.created_by}</div>,
    },
    {
      title: "Delivery Status",
      key: "po_delivery_status",
      width: 200,

      render: (data) => (
        <>
          <Select
            style={{ width: 180 }}
            disabled={!hasPermission("po.update")}
            onChange={(e) => {
              if (
                data?.live_location_link?.length > 0 ||
                e == "in_store" ||
                e == "dispatch_pending"
              ) {
                editPO(data?.id, { po_delivery_status: e }, "");
              } else {
                setPOData({
                  ...data,
                });
                setDeliveryLocationDrawerVisible(true);
              }
            }}
            placeholder="Select status"
            value={data?.po_delivery_status}
          >
            {status_type?.map((obj: any) => (
              <Option key={obj?.id} value={obj?.value}>
                {obj?.label}
              </Option>
            ))}
          </Select>
        </>
      ),
    },
    // {
    //   title: "Total",
    //   key: "total_po_raised_amount",
    //   render: (data) => (
    //     <>{parseFloat(data?.total_po_raised_amount ?? 0)?.toFixed(2)}</>
    //   ),
    // },
    {
      title: "Actions",
      key: "action",
      render: (data) => (
        <Space>
          <div
            onClick={() => {
              // window.open(PowoPdfOpenApi + data?.id + "?image=true", "_blank");
              window.open(PowoDeliveryChallanOpenApi + data?.id, "_blank");
            }}
          >
            <Tag
              onClick={() => {}}
              color={"blue"}
              style={{ cursor: "pointer" }}
            >
              Delivery Challan
            </Tag>
          </div>
          {getRocketBoxDiv(data)}
          <Link to={"/po-details/" + data?.id}>
            <Tag
              onClick={() => {}}
              color={"volcano"}
              style={{ cursor: "pointer" }}
            >
              See Details
            </Tag>
          </Link>
          <Tag
            color="geekblue"
            onClick={() => setAttachDeliveryDrawerVisible(data?.id)}
            style={{ cursor: "pointer" }}
          >
            Attach delivery
          </Tag>
          {/* <EyeOutlined
            onClick={() => {
              setId1(data?.id.toString());
              setBasicModal(true);
            }}
          /> */}
        </Space>
      ),
    },
  ];

  const getSelectedTab = () => {
    if (deliveriesView) {
      return "deliveries";
    }
    if (etasView) {
      return "etas";
    }

    return tab_type;
  };

  const getPOs = (params: any) => {
    setLoading(true);
    setPage(params?.page ?? 1);
    getPurchaseOrders({
      page: params?.page ?? 1,
      is_deleted: false,
      ...params,
    }).then((res) => {
      setData(res?.results);
      setTotalPos(res?.count);
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchBranchMembers().then((res) => {
      setMembers(res);
    });
    fetchUserDetails().then((res) => {
      setCurrentUser(res);
    });
    getVendors();
  }, []);

  useEffect(() => {
    getPurchaseOrderDetails(id1).then((res) => {
      let dataRes = res;
      console.log("dataRes", dataRes);
      dataRes.po_items = dataRes.po_items?.map((item: any) => {
        return {
          ...item,
          ...item?.estimate_item,
        };
      });
      console.log("dataRes2", dataRes);

      setData1({ ...dataRes });
    });
    fetchBranches().then((res) => {
      setBranchData(res);
    });
  }, [id1]);

  const fetchBasicDetails = (client_id: string) => {
    fetchClientDetails(client_id).then((res) => {
      setClientData(res);
    });
  };

  useEffect(() => {
    if (data1?.estimate?.client) {
      fetchBasicDetails(data1?.estimate?.client);
    }
  }, [data1]);

  useEffect(() => {
    getPOs({
      page: 1,
      created_at__gte: created_at__gte,
      created_at__lte: created_at__lte,
      dispatch_time__gte: dispatch_time__gte,
      dispatch_time__lte: dispatch_time__lte,
      created_by: created_by,
      po_delivery_status__in: tab_type,
      search,
      ordering: sortVal,
      vendor: vendor_id,
    });
  }, [
    status,
    created_by,
    vendor_name,
    created_at__gte,
    created_at__lte,
    dispatch_time__gte,
    dispatch_time__lte,
    // po_status,
    sortVal,
    tab_type,
    vendor_id,
  ]);

  // useEffect(() => {
  //   getPOs(page);
  // }, []);

  const editPO = (po_id: any, params: any, runFunc: string) => {
    editPurchaseOrder(po_id, params).then(async (res) => {
      getPOs({
        page: page,
        created_at__gte: created_at__gte,
        created_at__lte: created_at__lte,
        dispatch_time__gte: dispatch_time__gte,
        dispatch_time__lte: dispatch_time__lte,
        created_by: created_by,
        po_delivery_status__in: tab_type,
        search,
        ordering: sortVal,
        vendor: vendor_id,
      });
      if (res) {
        if (runFunc == "shipment_creation") {
          await rocketBoxShipmentCreation(po_id);
        }
      }
    });
  };

  const onDeliveryDetailsFinish = (values: any) => {
    setDeliveryLocationDrawerVisible(false);
    editPO(
      po_data?.id,
      {
        ...values,
        po_delivery_status: "dispatched",
      },
      ""
    );
  };

  const onEwayBillDetailsFinish = (values: any) => {
    setEwayBillDrawerVisible(false);
    editPO(
      po_data?.id,
      {
        ...values,
      },
      "shipment_creation"
    );
  };

  const onDownloadClick = async () => {
    const check =
      (created_at__gte === undefined || created_at__lte === undefined) &&
      (dispatch_time__gte === undefined || dispatch_time__lte === undefined);
    if (check) {
      message.error("Please select date range");
      return;
    }

    const params = {
      // created_at_start_date: created_at__gte,
      // created_at_end_date: created_at__lte,
      po_delivery_status: tab_type,
      is_deleted: "false",
      page: "1",
    } as Record<string, string>;

    if (dispatch_time__gte && dispatch_time__lte) {
      params["dispatch_time_start_date"] = dispatch_time__gte;
      params["dispatch_time_end_date"] = dispatch_time__lte;
    }
    if (created_at__gte && created_at__lte) {
      params["created_at_start_date"] = created_at__gte;
      params["created_at_end_date"] = created_at__lte;
    }

    message.loading({
      content: "Data will be sent to your email",
      key: "download",
    });

    await exportPOCSV(params);
  };

  const getRocketBoxDiv = (values: any) => {
    // if (values?.label_url) {
    //   return (
    //     <>
    //       <Link
    //         className=""
    //         to={"/delivery-tracking/" + values?.id}
    //         target="_blank"
    //       >
    //         <Tag color={"#2db7f5"} style={{ cursor: "pointer" }}>
    //           Track Delivery
    //         </Tag>
    //       </Link>
    //       <div
    //         onClick={() => {
    //           window.open(values?.label_url, "_blank");
    //         }}
    //       >
    //         <Tag
    //           onClick={() => {}}
    //           color={"#008B8B"}
    //           style={{ cursor: "pointer" }}
    //         >
    //           Print Label
    //         </Tag>
    //       </div>
    //     </>
    //   );
    // }
    // if (values?.rocket_box_order_id) {
    //   return (
    //     <div
    //       className={
    //         hasPermission("delivery.create")
    //           ? ""
    //           : "unclickable grey-color-text"
    //       }
    //       onClick={async () => {
    //         if (
    //           moment(new Date())
    //             .add((new Date(values?.dispatch_time) < new Date("2024-03-22") ? 5.5 : 0), "hours")
    //             .diff(values?.dispatch_time, "minutes") > 0
    //         ) {
    //           message.warning(
    //             "Dispatch time over. Please edit dispatch time and try again."
    //           );
    //           return;
    //         }
    //         if (
    //           values?.total_po_raised_amount >= 50000 &&
    //           !values?.eway_bill_no
    //         ) {
    //           setEwayBillDrawerVisible(true);
    //           return;
    //         }
    //         setLoading(true);
    //         await rocketBoxShipmentCreation(values?.id);

    //         getPOs({
    //           page: page,
    //           created_at__gte: created_at__gte,
    //           created_at__lte: created_at__lte,
    //           created_by: created_by,
    //           po_delivery_status: tab_type,
    //           search,
    //           ordering: sortVal,
    //         });
    //       }}
    //     >
    //       <Tag onClick={() => {}} color={"cyan"} style={{ cursor: "pointer" }}>
    //         Rocket Box Shipment Creation
    //       </Tag>
    //     </div>
    //   );
    // }
    // return (
    //   <div
    //     className={
    //       hasPermission("delivery.create") ? "" : "unclickable grey-color-text"
    //     }
    //     onClick={async () => {
    //       setLoading(true);
    //       await rocketBoxOrderCreation(values?.id);

    //       getPOs({
    //         page: page,
    //         created_at__gte: created_at__gte,
    //         created_at__lte: created_at__lte,
    //         created_by: created_by,
    //         po_delivery_status: tab_type,
    //         search,
    //         ordering: sortVal,
    //       });
    //     }}
    //   >
    //     <Tag onClick={() => {}} color={"cyan"} style={{ cursor: "pointer" }}>
    //       Rocket Box Order Creation
    //     </Tag>
    //   </div>
    // );

    return (
      <a
        href={`https://wa.me/+91${String(
          values?.estimate?.client?.contact
        ).slice(-10)}?text=I%27m%20${
          currentUser?.f_name
        }%20from%20the%20Material%20Depot%20delivery%20team.%20%20I%20have%20some%20updates%20regarding%20your%20order%20%28%5B${
          values?.estimate?.lead_id
        }%5D%29%3A%0A%0AIf%20you%20have%20any%20questions%20or%20need%20further%20assistance%2C%20please%20don%27t%20hesitate%20to%20contact%20us.`}
        target="_blank"
        rel="noreferrer"
      >
        <Tag onClick={() => {}} color={"cyan"} style={{ cursor: "pointer" }}>
          Whatsapp Message
        </Tag>
      </a>
    );
  };

  const pushOrdersToLoginext = async () => {
    await createLoginextOrders();
  };

  const tableView = useMemo(() => {
    if (deliveriesView) {
      return (
        <Table
          loading={loading}
          rowKey={"id"}
          columns={deliveriesColumns}
          scroll={{
            x: "100%",
          }}
          dataSource={deliveries}
          pagination={{
            current: deliveriesPage,
            total: deliveriesCount ?? 1,
            showSizeChanger: false,
            pageSize: 10,
            onChange: (p) => {
              setDeliveriesPage(p);
            },
          }}
        />
      );
    }

    if (etasView) {
      // return <Tracker />;
      return <TrackerByDeliveries />;
    }

    return (
      <>
        <Table
          loading={loading}
          rowKey={"id"}
          dataSource={data}
          columns={columns}
          pagination={false}
          scroll={{
            x: "100%",
          }}
          onRow={(record, index) => ({
            style: {
              background:
                record?.po_delivery_status == "dispatch_pending" &&
                moment(new Date())
                  .add(
                    new Date(record?.dispatch_time) < new Date("2024-03-22")
                      ? 5.5
                      : 0,
                    "hours"
                  )
                  .diff(record?.dispatch_time, "minutes") > 60
                  ? "#FFB4A3"
                  : record?.po_delivery_status == "dispatch_pending" &&
                    moment(new Date())
                      .add(
                        new Date(record?.dispatch_time) < new Date("2024-03-22")
                          ? 5.5
                          : 0,
                        "hours"
                      )
                      .diff(record?.dispatch_time, "minutes") > 20
                  ? "#FFFFC0"
                  : "",
              // Number(record?.quantity) === Number(record?.po_raised)
              //   ? "#84FACF"
              //   : Number(record?.quantity) > Number(record?.po_raised)
              //   ? "#FFFFC0"
              //   : "#FFB4A3",
            },
          })}
        />
        <Pagination
          current={page}
          total={total_pos ?? 1}
          showSizeChanger={false}
          pageSize={10}
          className="mt-3"
          onChange={(p) => {
            getPOs({
              page: p,
              created_at__gte: created_at__gte,
              created_at__lte: created_at__lte,
              dispatch_time__gte: dispatch_time__gte,
              dispatch_time__lte: dispatch_time__lte,
              created_by: created_by,
              po_delivery_status__in: tab_type,
              search,
              ordering: sortVal,
              vendor: vendor_id,
            });
          }}
        />
      </>
    );
  }, [
    columns,
    created_at__gte,
    created_at__lte,
    created_by,
    data,
    deliveries,
    deliveriesColumns,
    deliveriesCount,
    deliveriesPage,
    deliveriesView,
    dispatch_time__gte,
    dispatch_time__lte,
    etasView,
    loading,
    page,
    search,
    sortVal,
    tab_type,
    total_pos,
    vendor_id,
  ]);

  return (
    <div className="mx-3 my-3 px-3 py-2 white-color-bg">
      <div>
        <div className="d-flex justify-content-between">
          <h3>Delivery Orders</h3>
          <div className="d-flex gap-2">
            <div>
              <Select
                placeholder="Sort by"
                className="mt-0"
                style={{ width: 200 }}
                showArrow={true}
                value={sortVal}
                onChange={setSortVal}
                options={sortOptions.map((item: any) => ({
                  value: item?.value,
                  label: item?.label,
                }))}
              ></Select>
            </div>
            {dropdownVisible && <Button onClick={handleReset}>Reset</Button>}
            <Button onClick={handleButtonClick} className="pt-0">
              <FilterOutlined style={{ fontSize: 16 }} />
            </Button>
            <Input.Search
              type="text"
              style={{ maxWidth: 180 }}
              placeholder="Search"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              onSearch={(value) => {
                setCreated_by(undefined);
                setCreated_at__gte(undefined);
                setCreated_at__lte(undefined);
                setVendor_name(undefined);
                setStatus(undefined);
                setDispatchTime__gte(undefined);
                setDispatchTime__lte(undefined);
                setVendorId(null);
                // setPo_status(undefined);
                if (deliveriesView) {
                  fetchDeliveries({ page: 1, search: value });
                } else {
                  getPOs({ page: 1, search });
                }
              }}
            />
          </div>
        </div>
        {dropdownVisible && (
          <div className="row">
            <div className="d-flex flex-row justify-content-between">
              <div className="mr-2 col ">
                <RangePicker
                  style={{ width: "95%" }}
                  onChange={(event) => {
                    if (event) {
                      setCreated_at__lte(
                        event[1]?.add(1, "day")?.format("YYYY-MM-DD")
                      );
                      setCreated_at__gte(event[0]?.format("YYYY-MM-DD"));
                    }
                  }}
                />
                <div className="th-font-size-12">Choose Created Date</div>
              </div>
              <div className="mr-2 col ">
                <RangePicker
                  style={{ width: "95%" }}
                  onChange={(event) => {
                    if (event) {
                      setDispatchTime__lte(
                        event[1]?.add(1, "day")?.format("YYYY-MM-DD")
                      );
                      setDispatchTime__gte(event[0]?.format("YYYY-MM-DD"));
                    }
                  }}
                />
                <div className="th-font-size-12">Choose Dispatch Date</div>
              </div>
              <div className="mr-2 col">
                <Select
                  placeholder="Select Vendor"
                  style={{ width: "95%" }}
                  value={vendor_id}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label?.toLowerCase() ?? "").includes(
                      input?.toLowerCase()
                    )
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  onChange={(e) => {
                    setVendorId(e);
                  }}
                  options={vendors.map((item: any) => ({
                    value: item?.id,
                    label: item?.name,
                  }))}
                  allowClear
                ></Select>
                <div className="th-font-size-12 px-1">Vendor</div>
              </div>

              {/* <div className="col">
                <Select
                  placeholder="Select PO Status"
                  style={{ width: "95%" }}
                  value={po_status}
                  onChange={setPo_status}
                  options={status_type.map((item: any) => ({
                    value: item?.value,
                    label: item?.label,
                  }))}
                ></Select>
                <div className="th-font-size-12">PO Status</div>
              </div> */}
            </div>
          </div>
        )}

        <div className="d-flex justify-content-between">
          <div className="d-flex">
            <Radio.Group
              value={getSelectedTab()}
              onChange={(e) => {
                if (e.target.value === "deliveries") {
                  setDeliveriesView(true);
                  setEtasView(false);
                } else if (e.target.value === "etas") {
                  setEtasView(true);
                  setDeliveriesView(false);
                } else {
                  setDeliveriesView(false);
                  setEtasView(false);
                  setTabType(e.target.value);
                }
              }}
              style={{ marginBottom: 16 }}
              className="me-2"
            >
              <Radio.Button value="dispatch_pending,delivery_assigned">
                Dispatch pending / Delivery Assigned
              </Radio.Button>
              <Radio.Button value="in_store">In Store</Radio.Button>
              <Radio.Button value="dispatched">Dispatched</Radio.Button>
              <Radio.Button value="delivered">Delivered</Radio.Button>
              <Radio.Button value="deliveries">Deliveries</Radio.Button>
              <Radio.Button value="etas">ETAs</Radio.Button>
            </Radio.Group>
          </div>
          <Space>
            <Button
              type="primary"
              // Disabled if both created at and dispatch time are not selected,
              // Allow either created at or dispatch time to be selected
              disabled={
                (created_at__gte === undefined ||
                  created_at__lte === undefined) &&
                (dispatch_time__gte === undefined ||
                  dispatch_time__lte === undefined)
              }
              onClick={onDownloadClick}
            >
              Download CSV
            </Button>
            {/* <Button onClick={pushOrdersToLoginext}>
              Push Orders to LogiNext
            </Button> */}
            {hasPermission("delivery.loginext") && (
              <Button onClick={() => setShowLoginextCSVUpload(true)}>
                Upload LogiNext CSV
              </Button>
            )}
            <Button
              onClick={() => {
                setTripsDrawerVisible(true);
              }}
            >
              View Trips
            </Button>
          </Space>
        </div>
        <div className="my-2">
          {tableView}
          <Modal
            open={basicModal}
            width={1000}
            footer={null}
            style={{ padding: "0" }}
            onOk={() => setBasicModal(false)}
            onCancel={() => setBasicModal(false)}
          >
            <DetailsPreview
              id={id1}
              data={data1}
              client_data={client_data}
              branch_data={branch_data}
            />
          </Modal>
          <DeliveryLocationDrawer
            visible={delivery_location_drawer_visible}
            onFinish={onDeliveryDetailsFinish}
            onClose={() => {
              setDeliveryLocationDrawerVisible(false);
            }}
          />
          <EWayBillDrawer
            visible={eway_bill_drawer_visible}
            onFinish={onEwayBillDetailsFinish}
            onClose={() => {
              setEwayBillDrawerVisible(false);
            }}
          />
          <Drawer
            open={showLoginextCSVUpload}
            width={500}
            footer={null}
            onClose={() => setShowLoginextCSVUpload(false)}
            title="Upload LogiNext CSV"
          >
            <LoginextCSVUpload
              onClose={() => setShowLoginextCSVUpload(false)}
            />
          </Drawer>
          <Drawer
            open={tripsDrawerVisible}
            width={500}
            footer={null}
            onClose={() => setTripsDrawerVisible(false)}
            title="Trips"
          >
            <LoginextTrip />
          </Drawer>
          <Drawer
            open={Boolean(attachDeliveryDrawerVisible)}
            width={500}
            footer={null}
            onClose={() => setAttachDeliveryDrawerVisible(null)}
            title="Attach Delivery"
          >
            {attachDeliveryDrawerVisible && (
              <AttachDelivery
                poId={attachDeliveryDrawerVisible}
                onClose={() => {
                  setAttachDeliveryDrawerVisible(null);
                }}
              />
            )}
          </Drawer>
        </div>
      </div>
    </div>
  );
};

export default DeliveryList;
