import { message } from "antd";
import axios, { AxiosError } from "axios";
import { errorActions } from "../helpers/auth_helper";

export const createPI = async (value: any) => {
  try {
    return axios
      .post("https://api-dev2.materialdepot.in/apiV1/estimate/", value)
      .then((res) => {
        message.success("PI created succesfully");
        return res?.data;
      })
      .catch((error) => {
        errorActions(error, "Error creating PI. Please try again.");
        return false;
      });
  } catch (error) {
    return false;
  }
};

export const refundOrder = async (value: any) => {
  try {
    return axios
      .post("https://api-dev2.materialdepot.in/apiV1/refund/", value)
      .then((res) => {
        message.success("Order refund created succesfully");
        return res?.data;
      })
      .catch((error) => {
        console.log({ error });
        errorActions(
          error,
          "Error creating refund." + { error }.error?.response?.data
        );
        return false;
      });
  } catch (error) {
    return false;
  }
};

export const approveRefund = async (params: any) => {
  try {
    return axios
      .get(`https://api-dev2.materialdepot.in/apiV1/refund-initate/`, {
        params: { ...params },
      })
      .then((res) => {
        message.success("Order refunded succesfully");

        return res?.data;
      })
      .catch((error) => {
        errorActions(error, "Error approving refunds. Please try again.");
        return false;
      });
  } catch (error) {
    return false;
  }
};

export const declineRefund = async (params: any) => {
  try {
    return axios
      .get(`https://api-dev2.materialdepot.in/apiV1/refund-decline/`, {
        params: { ...params },
      })
      .then((res) => {
        message.success("Order refund declined succesfully");

        return res?.data;
      })
      .catch((error) => {
        errorActions(error, "Error declining refunds. Please try again.");
        return false;
      });
  } catch (error) {
    return false;
  }
};

export const getOrderRefunds = async (params: any) => {
  try {
    return axios
      .get(`https://api-dev2.materialdepot.in/apiV1/refund/`, {
        params: { ...params },
      })
      .then((res) => {
        return res?.data;
      })
      .catch((error) => {
        errorActions(error, "Error getting refunds. Please try again.");
        return false;
      });
  } catch (error) {
    return false;
  }
};

export const editPI = async (pi_id: any, params: any, asAdmin = false) => {
  try {
    return axios
      .put(`apiV1/estimate/${pi_id}/`, params, {
        params: {
          ...(asAdmin ? {} : { from_procurement: "true" }),
        },
      })
      .then((res) => {
        message.success("PI edited succesfully");
      })
      .catch((error) => {
        errorActions(error, "Error editing PI. Please try again.");
        return;
      });
  } catch (error) {}
};

export const editProFormaInvoiceItem = async (
  id: number | string,
  data: Record<string, string | number>
) => {
  console.log("debug>>> sending data", data);
  try {
    const response = await axios.put("apiV1/estimate-item/" + id + "/", data);
    return response.data;
  } catch (e) {
    errorActions(e as AxiosError, "Could not update PI item. Try again.");
    return null;
  }
};

export const editVariantHSNCode = async (
  variant_id: number,
  hsn_code: string
) => {
  try {
    return axios
      .put(
        `apiV1/variant-single-update-hsn-code/?variant_id=${encodeURIComponent(
          variant_id
        )}&hsn_code=${encodeURIComponent(hsn_code)}`
      )
      .then((res) => {
        message.success("HSN code updated succesfully");
      })
      .catch((error) => {
        errorActions(error, "Error updating HSN code. Please try again.");
        return;
      });
  } catch (error) {}
};

export const fetchProFormaInvoices = async (params: any) => {
  try {
    return axios
      .get(`https://api-dev2.materialdepot.in/apiV1/estimate-info/`, {
        params: { ...params, is_revised: false },
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        errorActions(error, "Error fetching PI. Please try again.");
        return;
      });
  } catch (error) {
    return [];
  }
};

export const fetchPIfromId = async (pi_id: string) => {
  try {
    return axios
      .get(`https://api-dev2.materialdepot.in/apiV1/estimate/`, {
        params: { id: pi_id },
      })
      .then((res) => {
        return res.data?.results[0];
      })
      .catch((error) => {
        errorActions(error, "Error fetching PI. Please try again.");
        return;
      });
  } catch (error) {
    return [];
  }
};

export const checkAndConfirmOrderPayment = async (pi_id: string) => {
  try {
    return axios
      .get(`https://api-dev2.materialdepot.in/apiV1/order-payment-status/`, {
        params: { estimate_id: pi_id },
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        errorActions(error, "Payment not done");
        return;
      });
  } catch (error) {
    return [];
  }
};

export const fetchPIRevisions = async (lead_id: string) => {
  try {
    return axios
      .get(`https://api-dev2.materialdepot.in/apiV1/estimate-revision/`, {
        params: { lead_id: lead_id },
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        errorActions(error, "Error fetching PI revisions. Please try again.");
        return;
      });
  } catch (error) {
    return [];
  }
};

export const fetchEstimateLogs = async (estimate_id: string) => {
  try {
    return axios
      .get(`https://api-dev2.materialdepot.in/apiV1/estimate-log/`, {
        params: { estimate: estimate_id },
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        errorActions(error, "Error fetching PI logs. Please try again.");
        return;
      });
  } catch (error) {
    return [];
  }
};

export const fetchEstimateLogsByLeadId = async (lead_id: string) => {
  try {
    return axios
      .get(`https://api-dev2.materialdepot.in/apiV1/estimate-log/`, {
        params: { estimate__lead_id: lead_id },
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        errorActions(error, "Error fetching PI logs. Please try again.");
        return;
      });
  } catch (error) {
    return [];
  }
};

export const createEstimateDuplicate = (estimate_id: string) => {
  return axios
    .get(
      `https://api-dev2.materialdepot.in/apiV1/estimate-duplicate/?estimate_id=${estimate_id}`
    )
    .then((res) => {
      return true;
    })
    .catch((error) => {
      errorActions(error, "Error duplicating estimate. Please try again.");
      return false;
    });
};

export const caluclateShippingPorterFromAddress = (payload: any) => {
  try {
    return axios
      .post(
        "https://api-dev2.materialdepot.in/apiV1/md-shipping-cal/",
        payload,
        {
          headers: {
            Authorization: `Basic ODk3OTk5NzA3MjptZEAwNTY4Iw==`,
          },
        }
      )
      .then((res) => {
        message.success(
          "Calculated Delivery charges approx: ₹" +
            (res?.data).toFixed(0) +
            ". Check and confirm with delivery team."
        );
        return res?.data;
      })
      .catch((error) => {
        errorActions(
          error,
          "Error in calculating delivery charges. Please fill it manually."
        );
        return false;
      });
  } catch (error) {
    return false;
  }
};

export const caluclateShippingCourierFromAddress = (payload: any) => {
  try {
    return axios
      .post(
        "https://api-dev2.materialdepot.in/apiV1/md-courier-cost-cal/",
        payload,
        {
          headers: {
            Authorization: `Basic ODk3OTk5NzA3MjptZEAwNTY4Iw==`,
          },
        }
      )
      .then((res) => {
        message.success(
          "Calculated Delivery charges approx: ₹" +
            (res?.data).toFixed(0) +
            ". Check and confirm with delivery team."
        );
        return res?.data;
      })
      .catch((error) => {
        errorActions(
          error,
          "Error in calculating delivery charges. Please fill it manually."
        );
        return false;
      });
  } catch (error) {
    return false;
  }
};

export async function getShippingChargesFromPincode(
  estimate_items: any,
  ship_pincode: any,
  total_amount: any,
  estimate_id: any
) {
  try {
    const response = await fetch(
      "https://api-dev2.materialdepot.in/apiV1/master-shipping/",
      {
        // Adding method type
        method: "POST",

        // Adding body or contents to send
        body: JSON.stringify({
          estimate_items,
          ship_pincode,
          total_amount,
          estimate_id,
        }),

        // Adding headers to the request
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
      }
    );
    return await response.json();

    // const response = await axios.post(
    //   "https://apiv2..materialdepot.in/apiV1/master-shipping/",
    //   {
    //     estimate_items,
    //     ship_pincode,
    //   },
    //   {
    //     headers: {
    //       Accept: "application/json",
    //     },
    //   }
    // );
    // return response.data;
  } catch (error) {
    return false;
  }
}

export async function getZoneFromPincode(pincode: any) {
  try {
    const response = await axios.get(
      `https://api-dev2.materialdepot.in/apiV1/pincode/?pincode=${pincode}`
    );
    return response.data;
  } catch (error) {
    return false;
  }
}

export const createPIfromOrderID = async (params: any) => {
  try {
    const response = await fetch(
      `https://api-dev2.materialdepot.in/apiV1/fetch-order/?order_id=` +
        params?.order_id,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    return [];
  }
};

export const createOrderFromPI = async (estimate_id: string) => {
  try {
    return axios
      .get(
        `https://api-dev2.materialdepot.in/apiV1/push-estimate-order?estimate_id=` +
          estimate_id
      )
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error("Some error occured");

        errorActions(error, "Error fetching PI revisions. Please try again.");
        return;
      });
  } catch (error) {
    return [];
  }
};

export const addInvoiceToPFI = async (value: any) => {
  try {
    return axios
      .post("https://api-dev2.materialdepot.in/apiV1/estimate-invoice/", value)
      .then((res) => {
        message.success("Invoice added succesfully");
      })
      .catch((error) => {
        errorActions(error, "Error adding Invoice. Please try again.");
        return;
      });
  } catch (error) {
    message.error("Error adding Invoice. Please try again.");
    return null;
  }
};

export const getPFIInvoices = async (estimate_id: any) => {
  try {
    return axios
      .get(
        "https://api-dev2.materialdepot.in/apiV1/estimate-invoice/?estimate=" +
          estimate_id
      )
      .then((res) => {
        return res?.data;
      })
      .catch((error) => {
        errorActions(error, "Error creating PO. Please try again.");
        return;
      });
  } catch (error) {
    message.error("Error creating PO. Please try again.");
    return null;
  }
};

export const removeInvoiceFromPI = async (id: any) => {
  try {
    return axios
      .delete("apiV1/estimate-invoice/" + id + "/")
      .then((res) => {
        message.success("Invoice removed succesfully");
      })
      .catch((error) => {
        errorActions(error, "Error removing invoice. Please try again.");
        return;
      });
  } catch (error) {
    message.error("Error creating PO. Please try again.");
    return null;
  }
};

export const pushEstimateToZoho = async (id: any) => {
  message.loading({ content: "Pushing PI to Zoho", key: "pushZohoPI" });
  return axios
    .get("https://api-dev2.materialdepot.in/apiV1/zoho-invoice/", {
      params: { estimate_id: id },
    })
    .then((res) => {
      // message.success("PI pushed to Zoho succesfully");
      message.success({
        content: "PI pushed to Zoho succesfully",
        key: "pushZohoPI",
      });
      return res?.data;
    })
    .catch((error) => {
      // errorActions(error, "Error pushing PI to Zoho. Please try again.");
      message.error({
        content: "Error pushing PI to Zoho. Please try again.",
        key: "pushZohoPI",
      });
      return false;
    });
};

export const getCouponList = async () => {
  try {
    return axios
      .get("https://api-dev2.materialdepot.in/apiV1/coupon-list/")
      .then((res) => {
        return res?.data;
      })
      .catch((error) => {
        errorActions(error, "Error creating PO. Please try again.");
        return;
      });
  } catch (error) {
    message.error("Error creating PO. Please try again.");
    return null;
  }
};

export const getCouponDiscount = async (value: any) => {
  try {
    return axios
      .post(
        "https://api-dev2.materialdepot.in/apiV1/estimate-validate-coupon/",
        value
      )
      .then((res) => {
        return res?.data;
      })
      .catch((error) => {
        errorActions(error, "Error creating PO. Please try again.");
        return;
      });
  } catch (error) {
    message.error("Error creating PO. Please try again.");
    return null;
  }
};

export interface EstimateFeedbackBase {
  remarks: string;
  in_store_exp_rating: number;
  delivery_rating: number;
  sales_interaction_rating: number;
  // Format: YYYY-MM-DD
  photoshoot_date: string | null;
}
export interface EstimateFeedbackRead extends EstimateFeedbackBase {
  id: number;
  estimate: any;
  created_at: string;
  modified_at: string;
}
export interface EstimateFeedbackCreate extends EstimateFeedbackBase {
  estimate_id: string;
}

export function getEstimateFeedback(
  id: string
): Promise<Array<EstimateFeedbackRead>> {
  return axios
    .get<Array<EstimateFeedbackRead>>(
      `https://api-dev2.materialdepot.in/apiV1/estimate-feedback/`,
      {
        params: { estimate_id: id },
      }
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      errorActions(error, "Error fetching feedback. Please try again.");
      return [] as Array<EstimateFeedbackRead>;
    });
}

export function createEstimateFeedback(
  feedback: EstimateFeedbackCreate
): Promise<EstimateFeedbackRead> {
  return axios
    .post<EstimateFeedbackRead>(
      `https://api-dev2.materialdepot.in/apiV1/estimate-feedback/`,
      feedback
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      errorActions(error, "Error creating feedback. Please try again.");
      return null as any;
    });
}

export function updateEstimateFeedback(
  feedback: EstimateFeedbackCreate,
  id: number
): Promise<EstimateFeedbackRead> {
  return axios
    .put<EstimateFeedbackRead>(
      `https://api-dev2.materialdepot.in/apiV1/estimate-feedback/?id=${id}`,
      feedback
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      errorActions(error, "Error updating feedback. Please try again.");
      return null as any;
    });
}
