import { CarFilled, CloseCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Divider,
  Drawer,
  Input,
  MenuProps,
  Modal,
  Select,
  Tag,
  message,
} from "antd";
import { Header } from "antd/es/layout/layout";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";

import { createOrUpdateBikUser, logBikEvent } from "../../../apis/bik.api";
import { fetchBranches } from "../../../apis/branch.api";
import {
  fetchClientAddresses,
  fetchClientDetails,
} from "../../../apis/client.api";
import {
  createPI,
  fetchPIfromId,
  getCouponDiscount,
  getShippingChargesFromPincode,
  getZoneFromPincode,
} from "../../../apis/pro_forma_invoice.api";
import AddClientAddressForm from "../../../components/client/add_client_address_form";
import PFICouponSection from "../../../components/pro_forma_invoice/pro_forma_invoice_create/pfi_coupon_section";
import PFICreateAdditionalCharges from "../../../components/pro_forma_invoice/pro_forma_invoice_edit/pfi_additional_charges";
import { PFIEditBillToAddress } from "../../../components/pro_forma_invoice/pro_forma_invoice_edit/pfi_bill_to_address";
import PFIItemTable from "../../../components/pro_forma_invoice/pro_forma_invoice_edit/pfi_item_table";
import PFIEditOrderDetails from "../../../components/pro_forma_invoice/pro_forma_invoice_edit/pfi_order_details";
import PFIPaymentTerms from "../../../components/pro_forma_invoice/pro_forma_invoice_edit/pfi_payment_terms";
import PFISelectBillingDetails from "../../../components/pro_forma_invoice/pro_forma_invoice_edit/pfi_select_billing_details";
import { PFIEditShipToAddress } from "../../../components/pro_forma_invoice/pro_forma_invoice_edit/pfi_ship_to_address";
import PFITermsAndConditionsSelect from "../../../components/pro_forma_invoice/pro_forma_invoice_edit/pfi_terms_and_conditions";
import RateContracts from "../../../components/pro_forma_invoice/rate_contract";
import TaxAmount from "../../../components/pro_forma_invoice/tax_amount";
import {
  checkIfAllValuesArePresent,
  getMissingFields,
} from "../../../helpers/pfi_checks";
import { useCallbackPrompt } from "../../../helpers/useCallback";
import { Client } from "../../../interfaces/client.interface";
import styles from "./index.module.scss";

const ProFormaInvoiceEdit = () => {
  const { pi_id } = useParams();
  const navigate = useNavigate();
  const [new_pi_id, setNewPIId] = useState<any>("");
  const [pricing, setPricing] = useState("public");
  const [client_studio_drawer_open, setClientStudioDrawerOpen] =
    useState(false);
  const [ship_pincode, setShipPincode] = useState<any>(null);
  const isFirstRender = useRef(true);
  const [checkout_available, setCheckoutAvailable] = useState(true);
  const [coupon_code_id, setCouponCodeId] = useState<any>(null);

  const [client_data, setClientData] = useState<Client | null>(null);
  const [delivery_charges_added, setDeliveryChargesAdded] = useState(false);
  const [showProductSearchDrawer, setShowProductSearchDrawer] = useState(false);
  const [branch_data, setBranchData] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [missing_fields, setMissingFields] = useState<Array<any>>([]);
  const [billing_address_visible, setBillingAddressVisible] =
    useState<boolean>(false);
  const [shipping_address_visible, setShippingAddressVisible] =
    useState<boolean>(false);
  const [is_store_pickup, setIsStorePickup] = useState<boolean>(false);
  const [coupon_code_after_apply, setCouponCodeAfterApply] = useState("");
  const [coupon_discount, setCouponDiscount] = useState(0);
  const [total_md_price_gst, setTotalMdPriceGst] = useState(0);

  const [client_addresses, setClientAddresses] = useState<Array<any>>([]);
  const [saved, setSaved] = useState(false);
  const [startedEditing, setStartedEditing] = useState(true);
  const [additional_charges_pincode, setAdditionalChargesPincode] =
    useState("");
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(startedEditing);
  const [coupon_charges, setCouponCharges] = useState<any>(null);
  const [showPincodeDialog, setShowPincodeDialog] = useState(false);
  const [transientPincodeInput, setTransientPincodeInput] = useState("");

  useEffect(() => {
    if (saved) {
      setStartedEditing(false);
    }
  }, [saved]);

  const checkOutsideDelivery = (pincode: any) => {
    if (pincode?.length == 6) {
      getZoneFromPincode(pincode).then((zone) => {
        if (zone?.delivery_zone_id != 1) {
          setCheckoutAvailable(false);
        }
      });
    }
  };

  const fetchCouponDiscount = () => {
    getCouponDiscount({
      coupon_code: coupon_code_after_apply,
      is_store_pickup: false,
      pincode: ship_pincode,
      estimate_items: data?.estimate_items,
      client_id: data?.client,
    }).then((prices: any) => {
      console.log("prices", prices);
      setCouponDiscount(prices?.total_discount);
      const charge = {
        charge_type: "Coupon Applied (" + coupon_code_after_apply + ")",
        amount: prices?.total_discount,
      };
      setData({ ...data, coupon: coupon_code_id });

      setCouponCharges(charge);
    });
  };

  useEffect(() => {
    if (coupon_code_after_apply?.length > 0) {
      fetchCouponDiscount();
    } else {
      setCouponCharges(null);
    }
  }, [coupon_code_after_apply]);

  useEffect(() => {
    if (saved && !startedEditing) {
      navigate("/pro-forma-invoice-details/" + new_pi_id);
    }
  }, [navigate, saved, startedEditing]);

  const defaultTaxes = [
    {
      id: 1,
      label: "None",
      value: "none",
      tax_value: 0,
    },
    {
      id: 6,
      label: "5% CGST/SGST",
      value: "cgst_sgst",
      tax_value: 5,
    },
    {
      id: 7,
      label: "12% CGST/SGST",
      value: "cgst_sgst",
      tax_value: 12,
    },
    {
      id: 8,
      label: "18% CGST/SGST",
      value: "cgst_sgst",
      tax_value: 18,
    },
    {
      id: 9,
      label: "28% CGST/SGST",
      value: "cgst_sgst",
      tax_value: 28,
    },
    {
      id: 2,
      label: "5% IGST",
      value: "igst",
      tax_value: 5,
    },
    {
      id: 3,
      label: "12% IGST",
      value: "igst",
      tax_value: 12,
    },
    {
      id: 4,
      label: "18% IGST",
      value: "igst",
      tax_value: 18,
    },
    {
      id: 5,
      label: "28% IGST",
      value: "igst",
      tax_value: 28,
    },
  ];

  const [data, setData] = useState<any>({
    estimate_items: [],
    gst: null,
    estimate_status: "draft",
    estimate_payment_terms: [],
    // documents: [],
    estimate_additionals: [],
    total_estimate_amount: 0,
  });

  const [client_add_address_drawer_open, setClientAddAddressDrawerOpen] =
    useState(false);

  const fetchBasicDetails = (client_id: string) => {
    fetchClientDetails(client_id).then((res) => {
      setClientData(res);
    });
  };
  const fetchAddresses = (client_id: string) => {
    fetchClientAddresses(client_id).then((res) => {
      setClientAddresses(res);
    });
  };

  const onClientAddAddressDrawerClose = () => {
    setClientAddAddressDrawerOpen(false);
  };

  const fetchEstimateDetails = (id: string, branch_id: string) => {
    fetchPIfromId(id).then((res) => {
      if (
        [
          "order_placed",
          "order_confirmed",
          "partly_shipped",
          "shipped",
          "partly_delivered",
          "delivered",
        ].includes(res?.estimate_status)
      ) {
        setStartedEditing(false);
        setTimeout(() => {
          message.error("Cannot edit a PI that's already placed.");
          navigate("/orders");
        }, 700);
        return;
      }

      const allItems = [...res?.estimate_items];

      allItems?.map((item) => {
        let gross_margin = 0;
        if (!isNaN(item.margin)) {
          gross_margin = item.margin / (1 + item.margin / 100);
        } else {
          gross_margin = 0;
        }
        if (item.product_handle?.length > 0) {
          item.disabled = true;
        }
        item.gross_margin = gross_margin;
        item.old_quantity = item.quantity;
      });

      const additional_charges = res?.estimate_additional_charges?.map(
        (item: any) => {
          return {
            ...item,
            total:
              Number(item.amount) +
              Number(item.amount) * Number(item.tax_value) * 0.01,
          };
        }
      );
      setIsStorePickup(res?.is_store_pickup);

      setData({
        ...res,
        branch: branch_id,
        estimate_items: allItems,
        estimate_additionals: additional_charges,
        estimate_id: Number(id),
        assigned_to: res?.assigned_to?.id ?? null,
        gst: res?.gst?.id ?? res?.gst,
        vendor_warehouse: res?.vendor_warehouse?.id,
      });
      fetchBasicDetails(res?.client);
      fetchAddresses(res?.client);
    });
  };

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      message.info(
        "Coupon will get removed when editing PIs. Please select and apply again"
      );
      if (pi_id) {
        console.log("pi_id", pi_id);
        fetchBranches().then((res) => {
          setBranchData(res);
          fetchEstimateDetails(pi_id, res?.id);
        });
      }
      return;
    }
  }, [pi_id]);

  useEffect(() => {
    if (data?.bill_address) {
      setBillingAddressVisible(true);
    }
    if (data?.ship_address) {
      setShippingAddressVisible(true);
      const pincode = client_addresses?.find(
        (address: any) =>
          address.id == (data?.ship_address?.id ?? data?.ship_address)
      )?.pincode;
      setShipPincode(pincode);
      // checkOutsideDelivery(pincode);
    }
    if (data?.bill_address) {
      const pincode = client_addresses?.find(
        (address: any) => address.id == data?.bill_address
      )?.pincode;
      setAdditionalChargesPincode(pincode);
    }
    setTotalMdPriceGst(getTotalAmount());

    // else if (data?.ship_address) {
    //   const pincode = client_addresses?.find(
    //     (address: any) => address.id == data?.ship_address
    //   )?.pincode;
    //   setAdditionalChargesPincode(pincode);
    //   setShipPincode(pincode);
    // }
  }, [data, client_addresses]);

  const setDataSource = (key: string, value: any) => {
    setLoading(true);
    if (data[key] !== value) {
      let new_data: any = {};
      new_data[key] = value;
      setData((old_data: any) => {
        console.log("baap ----->", key, value, "----->", {
          ...old_data,
          ...new_data,
        });

        return { ...old_data, ...new_data };
      });
    }
    setTimeout(() => {
      setLoading(false);
    }, 200);
  };

  // const items: MenuProps["items"] = [
  //   {
  //     key: "1",
  //     label: (
  //       <div
  //         onClick={() => {
  //           createEstimate({ isDraft: false });
  //         }}
  //       >
  //         Create Pro Forma Invoice
  //       </div>
  //     ),
  //   },
  //   {
  //     key: "2",
  //     label: <div onClick={() => {}}>Go back to client page</div>,
  //   },
  // ];

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <div
          onClick={() => {
            // caluclateDeliveryCharges("porter");
          }}
        >
          Porter charges
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div
          onClick={() => {
            // caluclateDeliveryCharges("courier");
          }}
        >
          Courier charges
        </div>
      ),
    },
  ];

  const openRateContract = () => {
    setShowProductSearchDrawer(true);
  };

  const onProductSelect = ({ builtProduct = null }) => {
    const product: any = builtProduct;

    var gross_margin = 0;
    var margin = 0;
    if (
      !(
        product.md_price_gst_public &&
        product.md_price_gst_msp &&
        product.md_price_gst_studio
      )
    ) {
      message.error("Missing few pricing values. Please contact data team.");
      return;
    }
    // if (
    //   product.md_price_gst_studio &&
    //   product.md_price_gst_studio > 0 &&
    //   pricing == "studio"
    // ) {
    //   gross_margin =
    //     ((parseFloat(product.md_price_gst_studio ?? 0) -
    //       parseFloat(product.md_price_gst_msp ?? 0)) /
    //       parseFloat(product.md_price_gst_studio ?? 1)) *
    //     100;
    //   margin = gross_margin / (1 - gross_margin / 100);
    // }

    if (product.md_price_gst_public && product.md_price_gst_public > 0) {
      gross_margin =
        ((parseFloat(product.md_price_gst_public ?? 0) -
          parseFloat(product.md_price_gst_msp ?? 0)) /
          parseFloat(product.md_price_gst_public ?? 1)) *
        100;
      margin = gross_margin / (1 - gross_margin / 100);
    }

    console.log("product ...", product);

    const newData = {
      variant: parseInt(product?.variant_id?.toString()),
      id: data?.estimate_items?.length + 1,
      boq_item: product?.product_name,
      hsn_code: product?.hsn_code,
      sku: product?.sku,
      brand: product?.brand?.brand_name,
      private_label_brand_name:
        product?.private_label_brand?.private_label_brand_name ??
        product?.brand?.brand_name,
      private_label_product_name:
        product?.private_label_product_name ?? product?.product_name,
      unit_selling_price: (
        product?.md_price_gst_msp /
        (product.gst ? parseFloat(product.gst) / 100 + 1 : 1.18)
      ).toFixed(2),
      quantity: "1",
      unit: product?.price_unit,
      margin: margin,
      gross_margin: gross_margin,
      tax_value: product.gst ? Number(product.gst) : "18",
      tax_type: "cgst_sgst",
      mrp: product?.mrp,
      disabled: true,
      md_price_gst: product?.md_price_gst_msp,
      product_handle: product.product_handle,
      variant_handle: product.variant_handle,
      category_name: product.category?.category_name,
      item_description: "",
      landing_price: (
        product.md_price_gst /
        (product.gst ? parseFloat(product.gst) / 100 + 1 : 1.18)
      ).toFixed(2),
      item_image: product?.variant_image[0]?.image_url ?? null,
      vendor: product?.vendor?.map((vendor: any) => vendor?.vendor__id),
    };
    setData({ ...data, estimate_items: [...data?.estimate_items, newData] });
  };

  const customProductAdd = (product: any, remove = false) => {
    if (remove) {
      const allItems = [...data.estimate_items];
      const index = allItems.findIndex(
        (item) => item.boq_item === product.product_name
      );
      allItems.splice(index, 1);
      setData({ ...data, estimate_items: allItems });
    } else {
      console.log("Onproductselect", product);

      onProductSelect({ builtProduct: product });
    }
  };

  const getTotalAmount = () => {
    let amount = 0;
    data?.estimate_items?.map((item: any) => {
      amount +=
        Number(item?.quantity) *
        ((Number(item?.unit_selling_price) +
          Number(item?.unit_selling_price) * (Number(item?.margin) / 100)) *
          ((Number(item?.tax_value) + 100) / 100));
    });
    data?.estimate_additionals?.map((obj: any) => {
      amount += Number(obj.total) ?? 0;
    }, 0);

    let new_data = { ...data };
    if (coupon_charges?.amount > 0) {
      amount -= coupon_charges?.amount;
    }
    new_data.total_estimate_amount = amount;
    return amount;
  };

  const addRow = () => {
    const newData = {
      id: data?.estimate_items.length + 1,
      boq_item: `Item ${data?.estimate_items.length + 1}`,
      hsn_code: "",
      sku: "",
      category_name: "",
      brand: ``,
      mrp: 0,
      unit_selling_price: 0,
      md_price_gst: 0,
      image: null,
      quantity: "1",
      unit: "",
      margin: 0,
      gross_margin: 0,
      tax_value: "18",
      tax_type: "cgst_sgst",
    };
    let items = [...data?.estimate_items];
    items.push(newData);
    setDataSource("estimate_items", items);
  };

  const createEstimate = ({ isDraft = false }) => {
    let requiredDetailsFilled = true;
    if (!checkout_available) {
      message.warning("Outstation orders are currently not being processed.");
      return;
    }

    setLoading(true);
    if (data?.estimate_items?.length < 1) {
      message.warning("Enter at least one item!");
      setLoading(false);

      return;
    }
    if (data?.estimate_additionals?.length < 1) {
      message.warning("Please enter delivery charges!");
      setLoading(false);
      return;
    }
    const full_percentage = data?.estimate_payment_terms?.reduce(
      (acc: number, obj: any) => {
        return acc + obj.percentage;
      },
      0
    );

    if (full_percentage !== 100) {
      message.warning("Total Percentage for terms should be 100!");
      setLoading(false);
      return;
    }
    let items = [] as any[];
    for (var i = 0; i < data?.estimate_items?.length; i++) {
      var item_data = data?.estimate_items[i];
      var lead_time = item_data?.lead_time;
      var lead_time_updated_at = item_data?.lead_time_updated_at;
      if (item_data?.old_quantity < item_data?.quantity) {
        lead_time = null;
        lead_time_updated_at = null;
      }

      const formData = {
        variant: item_data?.variant,
        boq_item: item_data.boq_item,
        quantity: Number(item_data.quantity),
        unit: item_data.unit,
        hsn_code: item_data.hsn_code,
        sku: item_data?.sku,
        brand: item_data.brand,
        unit_selling_price: Number(item_data.unit_selling_price)?.toFixed(2),
        margin: Number(item_data.margin)?.toFixed(2),
        tax_value: Number(item_data.tax_value)?.toFixed(2),
        tax_type: item_data.tax_type,
        mrp: Number(item_data.mrp),
        item_image: item_data.item_image,
        product_handle: item_data.product_handle,
        variant_handle: item_data.variant_handle,
        category_name: item_data.category_name,
        private_label_brand_name: item_data?.private_label_brand_name,
        private_label_product_name: item_data?.private_label_product_name,
        landing_price: item_data.landing_price
          ? Number(item_data.landing_price ?? 0).toFixed(2)
          : 0,
        item_description: "",
        lead_time: lead_time,
        lead_time_updated_at: lead_time_updated_at,
        remarks: item_data.remarks,
        vendor: item_data?.vendor,
      };
      items.push(formData);
      const currentFilled = checkIfAllValuesArePresent(formData, isDraft);
      if (!currentFilled) {
        const currentMissingFields = getMissingFields(formData);
        setMissingFields([
          ...missing_fields,
          {
            index: i,
            missingFields: currentMissingFields,
          },
        ]);
      }
      requiredDetailsFilled = currentFilled;
      requiredDetailsFilled = checkIfAllValuesArePresent(formData, isDraft);
    }
    console.log("data?.estimate_items", items);

    if (!requiredDetailsFilled) {
      message.error("Please fill all the required details of estimate items.");
      setLoading(false);

      return;
    }

    let final_formatting_data = { ...data };
    if (final_formatting_data?.gst?.id) {
      final_formatting_data.gst = final_formatting_data?.gst?.id;
    }
    if (final_formatting_data?.terms_and_condition?.id) {
      final_formatting_data.terms_and_condition =
        final_formatting_data?.terms_and_condition?.id;
    }
    if (final_formatting_data?.bill_address?.id) {
      final_formatting_data.bill_address =
        final_formatting_data?.bill_address?.id;
    }
    if (final_formatting_data?.ship_address?.id) {
      final_formatting_data.ship_address =
        final_formatting_data?.ship_address?.id;
    }

    if (!branch_data?.id) {
      message.error(
        "Branch data not fetched. Please try again in a few seconds."
      );
      setLoading(false);

      fetchBranches().then((res) => {
        setBranchData(res);
      });
      return;
    }

    let payload = {
      coupon: coupon_code_id,
      client: final_formatting_data?.client,
      terms_and_condition: final_formatting_data?.terms_and_condition,
      total_estimate_amount: final_formatting_data?.total_estimate_amount,
      terms_and_conditions_description:
        final_formatting_data?.terms_and_conditions_description,
      gst: final_formatting_data?.gst,
      estimate_status: isDraft ? "draft" : "quote_approval_pending",
      bill_address: final_formatting_data?.bill_address,
      bill_address_description: final_formatting_data?.bill_address_description,
      address: final_formatting_data?.address,
      ship_address: final_formatting_data?.ship_address,
      ship_address_description: final_formatting_data?.ship_address_description,
      estimate_additionals: final_formatting_data?.estimate_additionals,
      estimate_payment_terms: final_formatting_data?.estimate_payment_terms,
      estimate_id: final_formatting_data?.estimate_id,
      branch: branch_data?.id,
      is_store_pickup: is_store_pickup,
      estimate_items: items,
      is_outside_delivery: final_formatting_data?.is_outside_delivery,
      image: true,
      // documents: final_formatting_data?.documents,
      assigned_to: final_formatting_data?.assigned_to,
    };

    if (is_store_pickup) {
      payload.is_outside_delivery = false;
    }

    createPI({ ...payload, is_draft: isDraft }).then((res) => {
      setLoading(false);

      if (res) {
        createOrUpdateBikUser({
          f_name: client_data?.f_name,
          l_name: client_data?.l_name,
          contact: String(client_data?.contact),
          email: client_data?.email,
          pincode: client_data?.pincode,
          user_source: "PI",
        }).then(() => {
          return Promise.all(
            items.map(async (item: any) => {
              return logBikEvent(
                "addToCart",
                {
                  productId: item?.variant_handle,
                  productName: item?.boq_item,
                  variantId: item?.variant_handle,
                  variantName: item?.boq_item,
                  price: item?.unit_selling_price,
                },
                {
                  f_name: client_data?.f_name,
                  contact: String(client_data?.contact),
                  email: client_data?.email,
                }
              );
            })
          );
        });
        setNewPIId(res?.id);
        setTimeout(() => {
          setSaved(true);
        }, 500);
      }
    });
  };

  const removeBillingAddress = () => {
    setBillingAddressVisible(false);
    setDataSource("bill_address", null);
    setDataSource("bill_address_description", null);
    setDataSource("address", null);
  };

  const removeShippingAddress = () => {
    setShippingAddressVisible(false);
    setDataSource("ship_address", null);
    setDataSource("ship_address_description", null);
  };

  const setTaxForAllItems = (tax_id: any) => {
    const tax_selected = defaultTaxes.find((tax) => tax.id == tax_id);
    const allItems = [...data?.estimate_items];
    allItems?.map((item) => {
      item.tax_type = tax_selected?.value;
      item.tax_value = tax_selected?.tax_value;
    });
    setData({ ...data, estimate_items: allItems });
  };

  const setMarginForAllItems = (gross_margin: any) => {
    const allItems = [...data?.estimate_items];
    allItems?.map((item) => {
      item.gross_margin = gross_margin;
      item.margin = gross_margin / (1 - gross_margin / 100);
    });
    console.log("allItems", allItems);
    setData({ ...data, estimate_items: allItems });
  };

  const onItemQunatityChange = (handle: string, quantity: string) => {
    setData((prevData: typeof data) => ({
      ...prevData,
      estimate_items: [
        ...prevData.estimate_items?.filter(
          (item: any) => item?.variant_handle !== handle
        ),
        {
          ...prevData.estimate_items?.find(
            (item: any) => item?.variant_handle === handle
          ),
          quantity,
        },
      ],
    }));
  };

  const caluclateDeliveryCharges = async (override_pincode?: string) => {
    setLoading(true);
    let items: Array<any> = data?.estimate_items?.filter(
      (item: any) => item.variant
    );
    if (items?.length > 0) {
      let res: any = null;
      let is_outside_delivery = false;

      let total_amount = getTotalAmount();

      res = await getShippingChargesFromPincode(
        items,
        override_pincode ?? ship_pincode,
        total_amount,
        data?.id
      );

      let zone = await getZoneFromPincode(override_pincode ?? ship_pincode);

      if (!(zone?.delivery_zone_id == 1)) {
        is_outside_delivery = true;
      }
      // if (res?.express_shipping_cost != 0) {
      //   res = res?.express_shipping_cost;

      // res = await caluclateShippingPorterFromAddress({
      //   estimate_items: items,
      //   ship_address_description: data?.ship_address_description,
      //   pincode: data?.ship_pincode,
      // });
      // } else {
      if (res?.standard_shipping_cost != 0) {
        res = res?.standard_shipping_cost;
      } else {
        res = res?.courier_shipping_cost;
      }

      // res = await caluclateShippingCourierFromAddress({
      //   estimate_items: items,
      //   ship_pincode: data?.ship_pincode,
      // });
      // }

      message.success(
        "Calculated Delivery charges approx: ₹" +
          res?.toFixed(0) +
          ". Check and confirm with delivery team."
      );
      if (res) {
        const transportChargeIndex = data?.estimate_additionals?.findIndex(
          (item: any) =>
            item.charge_type.includes("Transport") ||
            item.charge_type.includes("Delivery")
        );

        if (transportChargeIndex === -1) {
          setData({
            ...data,
            is_outside_delivery: is_outside_delivery,
            estimate_additionals: [...data?.estimate_additionals],
          });
        } else {
          const newAdditionals = [...data?.estimate_additionals];
          const charge = {
            charge_type: "Transport Charges",
            amount: (
              res /
              (1 + (newAdditionals[transportChargeIndex].tax_value ?? 18) / 100)
            ).toFixed(2),
            tax_value: newAdditionals[transportChargeIndex].tax_value ?? 18,
            tax_type:
              newAdditionals[transportChargeIndex].tax_type ?? "cgst_sgst",
            total: res.toFixed(0),
          };
          newAdditionals[transportChargeIndex] = charge;

          setData({
            ...data,
            is_outside_delivery: is_outside_delivery,
            estimate_additionals: newAdditionals,
          });
        }
      }
    } else {
      message.warning("Please enter atleast one item.");
    }
    setLoading(false);

    setDeliveryChargesAdded(true);
  };

  return (
    <section className={`lighter-grey-color-bg`}>
      <Modal
        title="Confirm leaving the page?"
        open={showPrompt}
        onOk={async () => {
          cancelNavigation();
          // setLoading(false);
          // confirmNavigation();
        }}
        onCancel={() => {
          confirmNavigation();
        }}
        okText="Cancel"
        cancelText="Leave without saving"
      >
        <p style={{ margin: "0 1rem" }}>Unsaved changes might be lost.</p>
      </Modal>
      <Header
        style={{
          position: "sticky",
          top: 0,
          zIndex: 1,
          width: "100%",
        }}
      >
        <div className="d-flex justify-content-between">
          <div className="d-flex gap-4 align-items-center">
            <div className="primary-color-text th-font-size-20 th-font-weight-700">
              Pro Forma Edit
            </div>
            <Link
              className="text-decoration-none"
              to={"/client/" + client_data?.id}
              target="_blank"
            >
              <div className=" th-font-weight-600 clover-green-color-text">
                Client :
                <span className=" th-font-weight-400">
                  {" "}
                  {client_data?.f_name}
                </span>
              </div>
            </Link>
            {/* <div className=" th-font-weight-600">
              Quotation No. :
              <span className=" th-font-weight-400"> {data?.estimate_no}</span>
            </div> */}
            <div className=" th-font-weight-600">
              Lead ID :
              <span className=" th-font-weight-400"> {data?.lead_id}</span>
            </div>
          </div>
          <div className="d-flex align-items-center">
            <div className="d-flex align-items-center gap-2">
              {/* <Button
                type="default"
                onClick={() => {
                  createEstimate({ isDraft: true });
                }}
                className="th-font-weight-700 d-flex align-items-center"
              >
                Save as draft
              </Button> */}

              {data?.ship_address ? (
                <>
                  {/* <Dropdown menu={{ items }} placement="bottomRight"></Dropdown> */}

                  <Button
                    type="primary"
                    className="th-font-weight-700 d-flex align-items-center"
                    onClick={() => {
                      caluclateDeliveryCharges();
                    }}
                  >
                    Calculate Delivery charges
                  </Button>
                  <Button
                    loading={loading}
                    type="primary"
                    onClick={() => {
                      createEstimate({ isDraft: false });
                    }}
                    disabled={!delivery_charges_added}
                    className="th-font-weight-700 d-flex align-items-center"
                  >
                    Save
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    onClick={() => {
                      setShowPincodeDialog(true);
                    }}
                  >
                    Calculate Shipping Charge From Pincode
                  </Button>
                  <Button
                    type="primary"
                    loading={loading}
                    onClick={() => {
                      createEstimate({ isDraft: false });
                    }}
                    className="th-font-weight-700 d-flex align-items-center"
                  >
                    Save
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
      </Header>
      <div className={styles.table_content}>
        <div className="m-4 p-4 px-5 white-color-bg">
          <img
            alt="logo"
            src="/main_logo.svg"
            style={{ height: "80px" }}
            className="mt-2"
          />
          <div className="row gap-2 my-4">
            <div className="col">
              <PFISelectBillingDetails setData={setDataSource} data={data} />
            </div>
            <div className="col">
              <PFIEditOrderDetails
                branch_data={branch_data}
                setClientPricing={setPricing}
                client_data={client_data}
                setClientStudioDrawerOpen={setClientStudioDrawerOpen}
                setData={setDataSource}
                data={data}
                client_studio_drawer_open={client_studio_drawer_open}
                fetchBasicDetails={() =>
                  client_data && fetchBasicDetails(client_data.id)
                }
              />
            </div>
          </div>
          <div className="d-flex gap-2">
            <Button
              onClick={() => {
                setClientAddAddressDrawerOpen(true);
              }}
            >
              Add Client Address
            </Button>
            <Button
              loading={loading}
              onClick={() => {
                fetchAddresses(client_data?.id ?? "");
              }}
            >
              Refresh Address
            </Button>
          </div>
          <div className="row gap-2 my-4">
            <div className="col ">
              {!billing_address_visible ? (
                <>
                  <div className="d-flex justify-content-center border p-4">
                    <Tag
                      style={{ cursor: "pointer" }}
                      color="#157286"
                      onClick={() => setBillingAddressVisible(true)}
                      className="th-font-size-14 th-font-weight-600 p-2 px-3"
                    >
                      Click here to add Billing address
                    </Tag>
                  </div>
                </>
              ) : (
                <>
                  <PFIEditBillToAddress
                    removeBillingAddress={removeBillingAddress}
                    client_addresses={client_addresses}
                    setData={setData}
                    data={data}
                  />
                </>
              )}
            </div>
            <div className="col">
              {!shipping_address_visible ? (
                <>
                  {is_store_pickup ? (
                    <>
                      <div className="d-flex justify-content-center border p-4">
                        <Tag
                          style={{ cursor: "pointer" }}
                          color="green"
                          onClick={() => setIsStorePickup(false)}
                          className="th-font-size-16 th-font-weight-600 p-2 px-3 d-flex justify-content-center"
                        >
                          <CarFilled className="th-font-size-22 me-2" />
                          Store Pickup
                          <CloseCircleOutlined />
                        </Tag>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="d-flex justify-content-center border p-4">
                        <Tag
                          style={{ cursor: "pointer" }}
                          color="#157286"
                          onClick={() => setShippingAddressVisible(true)}
                          className="th-font-size-14 th-font-weight-600 p-2 px-3"
                        >
                          Click here to add Shipping address
                        </Tag>
                        {/* <div className="th-font-size-14 th-font-weight-600 p-2 px-3">
                          OR
                        </div>
                        <Tag
                          style={{ cursor: "pointer" }}
                          color="#157286"
                          onClick={() => {
                            setIsStorePickup(true);
                          }}
                          className="th-font-size-14 th-font-weight-600 p-2 px-3"
                        >
                          Store pickup
                        </Tag> */}
                      </div>
                    </>
                  )}
                </>
              ) : (
                <>
                  <PFIEditShipToAddress
                    removeShippingAddress={removeShippingAddress}
                    client_addresses={client_addresses}
                    setData={setData}
                    data={data}
                    setShipPincode={setShipPincode}
                  />
                </>
              )}
            </div>
          </div>
          <Divider />
          <div className="mt-3 d-flex justify-space-between">
            <div
              style={{
                display: "flex",
                flex: 1,
                alignItems: "center",
              }}
              className="gap-2"
            >
              {/* <Button onClick={() => addRow()} type="primary" className="mr-2">
                Add row
              </Button> */}

              <Button onClick={openRateContract} className="mr-2">
                Rate Con. & Bulk Upload
              </Button>
            </div>
            <div className="d-flex gap-3">
              {/* <div style={{ display: "flex", justifyContent: "end" }}>
                <div style={{ width: 180 }}>
                  <div style={{ textAlign: "right" }}>
                    Set margin for all items
                  </div>
                  <Input
                    type="number"
                    onWheel={(event) => event.currentTarget.blur()}
                    onChange={(e) => {
                      setMarginForAllItems(e.target.value);
                    }}
                  />
                </div>
              </div> */}
              <div style={{ display: "flex", justifyContent: "end" }}>
                <div style={{ width: 180 }}>
                  <div style={{ textAlign: "right" }}>
                    Set tax for all items
                  </div>
                  <Select
                    size="middle"
                    style={{ textAlign: "right", width: "100%" }}
                    onChange={(value) => setTaxForAllItems(value)}
                    key="all_tax"
                  >
                    {defaultTaxes.map((tax) => (
                      <Select.Option
                        value={tax.id}
                        style={{ textAlign: "right" }}
                        key={tax.id}
                      >
                        {tax.label}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
          </div>
          <Divider />
          <div>
            <PFIItemTable
              data={data}
              setData={setDataSource}
              loading={loading}
            />
            {/* <Table
              columns={columns}
              dataSource={data?.estimate_items}
              scroll={{ x: "max-content" }}
              rowKey={(record) => record.id}
            /> */}
          </div>{" "}
          <Divider />
          <div className="row">
            <div className="col">
              <div>
                <PFIPaymentTerms setData={setDataSource} data={data} />
              </div>
              <Divider />
              <div>
                <PFITermsAndConditionsSelect
                  branch_data={branch_data}
                  setData={setDataSource}
                  data={data}
                />
              </div>
              {/* <Divider /> */}

              {/* <div>
                <div className="">
                  <div>
                    <h6 className="th-font-weight-600">Documents</h6>{" "}
                    {"(Total files size should be less than 25MB)"}
                  </div>
                  <PFIEditDocumentUpload
                    documents={data?.documents}
                    loading={false}
                    setData={setDataSource}
                    client_id={"user_id"}
                  />
                  {data?.documents?.map((document: any, index: any) => {
                    return (
                      <div style={{ padding: 8 }}>
                        <div
                          style={{
                            border: "1px #D1D0CE solid",
                            padding: 4,
                            cursor: "pointer",
                            fontWeight: 500,
                          }}
                          onClick={() => {
                            // openInNewTab(document?.file);
                          }}
                        >
                          {document?.file_name ??
                            document?.file?.replace(
                              "https://mysite-powo-pdfs.s3.ap-south-1.amazonaws.com/media/porject_files/",
                              ""
                            )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div> */}
            </div>
            <div className="col">
              <div>
                <TaxAmount items={data?.estimate_items ?? []} />
              </div>
              <Divider />
              <div>
                <PFICreateAdditionalCharges
                  setData={setDataSource}
                  data={data}
                  pincode={additional_charges_pincode}
                />
              </div>
              {coupon_charges && (
                <>
                  {" "}
                  <div>
                    Coupon {coupon_charges.charge_type}: {coupon_charges.amount}
                  </div>
                </>
              )}

              <PFICouponSection
                setCouponCodeAfterApply={setCouponCodeAfterApply}
                coupon_discount={coupon_discount}
                setCouponCodeId={setCouponCodeId}
                total_md_price_gst={total_md_price_gst}
                setCouponDiscount={setCouponDiscount}
                coupon_code_after_apply={coupon_code_after_apply}
                user_id={data?.client ?? ""}
              />
              <Divider />
              <div>
                <div className="d-flex justify-content-between">
                  <div className="">
                    <h6>Total Amount</h6>
                  </div>
                  <div className="">
                    ₹ {Math.ceil(Number(getTotalAmount()))}
                    (Rounded off)
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Drawer
        title="Search Products"
        width={"50%"}
        onClose={() => {
          setShowProductSearchDrawer(false);
        }}
        open={showProductSearchDrawer}
      >
        <RateContracts
          customOnAdd={customProductAdd}
          added={data?.estimate_items?.map((item: any) => ({
            handle: item["variant_handle"],
            quantity: item["quantity"],
          }))}
          onQuantityChange={onItemQunatityChange}
          narrow
        />
      </Drawer>
      <Drawer
        title="Add Address"
        placement="right"
        onClose={onClientAddAddressDrawerClose}
        open={client_add_address_drawer_open}
        drawerStyle={{ height: "100%" }}
        width={500}
      >
        <AddClientAddressForm
          user_id={client_data?.id}
          onClientAddAddressDrawerClose={onClientAddAddressDrawerClose}
          fetchAddresses={fetchAddresses}
        />
      </Drawer>

      <Modal
        title="Calculate delivery charges"
        open={showPincodeDialog}
        onOk={() => {
          if (transientPincodeInput?.length !== 6) {
            message.error("Invalid Pincode. Please enter a valid pincode.");
            return;
          }

          caluclateDeliveryCharges(transientPincodeInput);
          setShowPincodeDialog(false);
        }}
        onCancel={() => {
          setShowPincodeDialog(false);
        }}
        okText="Calculate"
        cancelText="Cancel"
      >
        <Input
          placeholder="Enter Pincode"
          onChange={(e) => {
            setTransientPincodeInput(e.target.value);
          }}
          style={{ width: 200 }}
        />
      </Modal>
    </section>
  );
};

export default ProFormaInvoiceEdit;
