import { DatePicker, Space, Tag } from "antd";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { fetchUserDetails } from "../../../../apis/user.api";
import { usePermissions } from "../../../../custom_hooks/permission/permission_hook";
import { range } from "../../../client/add_client_booking_form";

export interface BankDetails {
  bank_account_number: string;
  bank_ifsc: string;
  branch_upi: string;
}
export interface POOrderDetailsProps {
  created_by: string;
  created_at?: any;
  estimate_created_at?: any;
  live_location_link: string;
  client: string;
  lead_id: string;
  quotation_no: string;
  status: string;
  bank_details: BankDetails;
  dispatch_time: string;
  handleChangeDispatchDateTime: (dateString: string) => unknown;
  estimate_additional_charges?: Array<any>;
}

export const dispatchDateAllowChangeIDs = [
  // "33898f77-665e-4f92-8807-3a9efb7f9dd8",
  "50a0ff56-2500-4783-939b-27402e251c80", // Sanjay
  "9d2086b5-7ae7-4a46-9fe2-6cfd47dc585a", // Aruna
  "404f8ca6-0cca-4acc-9883-fd0498804dc0", // Sarthak
  "bd29266c-c428-4f89-8eee-8a08e2bcfa8b", // Manish
  "1e0411d9-a5b1-45bd-bd2d-02c5bbcc0962", // Gourab
  "020ba74c-6e2f-4cf6-bd24-e9363f6f8bca", // Yogi
  "3a8ae7b4-40cf-442b-9f88-7f1a3d3ad01b", // Kokila
];

const POOrderDetails = ({
  created_by,
  created_at,
  estimate_created_at,
  live_location_link,
  client,
  lead_id,
  quotation_no,
  status,
  bank_details,
  dispatch_time,
  handleChangeDispatchDateTime,
  estimate_additional_charges,
}: POOrderDetailsProps) => {
  const [userDetails, setUserDetails] = useState<any>(null);
  const { hasPermission } = usePermissions();

  useEffect(() => {
    fetchUserDetails().then((data) => {
      setUserDetails(data);
    });
  }, [created_by]);

  const unloading = useMemo(() => {
    if (!estimate_additional_charges) return null;

    const unloading = estimate_additional_charges.find((charge) =>
      charge?.charge_type?.toLowerCase().includes("unloading")
    );
    if (!unloading) return null;

    const amount = parseFloat(unloading?.amount);

    if (amount === 0) {
      return null;
    }

    return (
      <Space className="py-2">
        <Tag color="green">Unloading Paid For: {unloading?.amount}</Tag>
      </Space>
    );
  }, [estimate_additional_charges]);

  return (
    <div className="p-3 py-2 row mb-2">
      <div className="th-font-size-16 th-font-weight-600 pb-3 primary-color-text">
        Order Details
      </div>
      <div className="col">
        <div className="row">
          <div className="col-4 th-font-weight-500">Created By</div>
          <div className="col-8 pb-1">
            <div className="d-flex">
              <div className="pe-1">:</div>
              <div>{created_by}</div>
            </div>
          </div>
        </div>
        <div className="row py-2">
          <div className="col-4 th-font-weight-500">PO Created At</div>
          <div className="col-8">
            <div className="d-flex">
              <div className="pe-1">:</div>
              <div>{dayjs(created_at).format("DD MMM YYYY, HH:mm")}</div>
            </div>
          </div>
        </div>
        <div className="row py-2">
          <div className="col-4 th-font-weight-500">PI Created At</div>
          <div className="col-8">
            <div className="d-flex">
              <div className="pe-1">:</div>
              <div>
                {dayjs(estimate_created_at).format("DD MMM YYYY, HH:mm")}
              </div>
            </div>
          </div>
        </div>
        <div className="row py-2">
          <div className="col-4 th-font-weight-500">Client </div>
          <div className="col-8">
            <div className="d-flex">
              <div className="pe-1">:</div>
              <div>{client}</div>
            </div>
          </div>
        </div>
        <div className="row py-2">
          <div className="col-4 th-font-weight-500">Lead ID</div>
          <div className="col-8">
            <div className="d-flex">
              <div className="pe-1">:</div>
              <div>{lead_id}</div>
            </div>
          </div>
        </div>
        {/* <div className="row py-2">
          <div className="col-4 th-font-weight-500">Quotation No.</div>
          <div className="col-8">
            <div className="d-flex">
              <div className="pe-1">:</div>
              <div>{quotation_no}</div>
            </div>
          </div>
        </div> */}
        <div className="row py-2">
          <div className="col-4 th-font-weight-500">Status</div>
          <div className="col-8">
            <div className="d-flex">
              <div className="pe-1">:</div>
              <div>{status}</div>
            </div>
          </div>
        </div>
        <div className="row py-2">
          <div className="col-4 th-font-weight-500">Bank Details</div>
          <div className="col-8">
            <div className="d-flex">
              <div className="pe-1">:</div>
              <div className="">
                <div>Account Number ({bank_details?.bank_account_number})</div>
                <div>IFSC Code ({bank_details?.bank_ifsc})</div>
                <div>UPI ({bank_details?.branch_upi})</div>
              </div>
            </div>
          </div>
        </div>
        <div className="row py-2">
          <div className="col-4 th-font-weight-500">Location Link</div>
          <div className="col-8">
            <div className="d-flex">
              <div>{live_location_link}</div>
            </div>
          </div>
        </div>
        <div className="row py-2">
          <div className="col-4 th-font-weight-500">Dispatch Date & Time</div>
          <div className="col-8">
            {dispatchDateAllowChangeIDs.includes(userDetails?.id) ? (
              <DatePicker
                showSecond={false}
                showTime={{
                  minuteStep: 30,
                  format: "HH:mm",
                  hideDisabledOptions: true,
                }}
                disabledTime={(current: any) => {
                  return {
                    disabledHours: () => range(0, 10).concat(range(21, 24)),
                  };
                }}
                className="w-100"
                placeholder="Select Dispatch Time"
                value={dayjs(dispatch_time ?? null)}
                onChange={(e: any, dateString) => {
                  // setData("dispatch_time", dateString);
                  handleChangeDispatchDateTime(dateString);
                }}
              />
            ) : (
              <div>{dayjs(dispatch_time).format("DD MMM YYYY, HH:mm")}</div>
            )}

            {/* {hasPermission("po.update_dispatch_time") ? (
              <DatePicker
                showSecond={false}
                showTime={{
                  minuteStep: 30,
                  format: "HH:mm",
                  hideDisabledOptions: true,
                }}
                disabledTime={(current: any) => {
                  return {
                    disabledHours: () => range(0, 10).concat(range(18, 24)),
                  };
                }}
                className="w-100"
                placeholder="Select Dispatch Time"
                value={dayjs(dispatch_time ?? null)}
                onChange={(e: any, dateString) => {
                  // setData("dispatch_time", dateString);
                  handleChangeDispatchDateTime(dateString);
                }}
              />
            ) : (
              <div>{dayjs(dispatch_time).format("DD MMM YYYY, HH:mm")}</div>
            )} */}
          </div>
        </div>
        {unloading}
      </div>
    </div>
  );
};

export default POOrderDetails;
