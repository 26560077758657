import { Button, Form, Input, Select, Space } from "antd";
import { createFootfallRecord } from "../../../apis/client.api";

export interface FootfallProps {
  onClose: () => void;
}

export function Footfall({ onClose }: FootfallProps) {
  const [form] = Form.useForm();

  const onSubmit = async (values: any) => {
    await createFootfallRecord(values);
    form.resetFields();
    onClose();
  };

  return (
    <div>
      <Form layout="vertical" onFinish={onSubmit}>
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: "Please enter your name",
            },
            {
              pattern: /^[a-zA-Z ]*$/,
              message: "Please enter a valid name",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Contact"
          name="contact"
          rules={[
            {
              required: true,
              message: "Please enter your contact number",
            },
            {
              pattern: /^[0-9]{10}$/,
              message: "Please enter a valid 10 digit contact number",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Source" name="source">
          <Select placeholder="Select source">
            <Select.Option value="Online">Online</Select.Option>
            <Select.Option value="Referral">Referral</Select.Option>
            <Select.Option value="Others">Others</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Purpose of visit" name="pov">
          <Input.TextArea />
        </Form.Item>

        <Form.Item>
          <Space>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
}
