import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Checkbox, Form, Input, Select, Space } from "antd";
import React, { useEffect, useState } from "react";
import { getAddressFromPincode } from "../../../../apis/misc.api";

interface PFICreateAdditionalChargesProps {
  setData: Function;
  data: any;
  pincode: string;
}

const PFICreateAdditionalCharges = ({
  setData,
  data,
  pincode,
}: PFICreateAdditionalChargesProps) => {
  const { TextArea } = Input;

  const { Option } = Select;

  const taxTypes: Array<any> = [
    {
      id: 1,
      label: "None",
      value: "none",
    },
    {
      id: 2,
      label: "CGST/SGST",
      value: "cgst_sgst",
    },
    {
      id: 4,
      label: "IGST",
      value: "igst",
    },
  ];

  const [tax_type, setTaxType] = useState("cgst_sgst");

  const onFinish = (values: any) => {
    console.log("Received values of form:", values);
  };

  const onAdd = () => {
    setData("estimate_additionals", [
      ...data?.estimate_additionals,
      {
        charge_type: "Additional Charge",
        amount: 0,
        tax_value: 18,
        tax_type: tax_type,
        total: 0,
      },
    ]);
  };

  const onRemove = (index: number) => {
    var arr = [...data?.estimate_additionals];
    arr.splice(index, 1);

    setData("estimate_additionals", arr);
  };

  useEffect(() => {
    if (pincode?.length == 6) {
      getAddressFromPincode(pincode).then((res: any) => {
        if (res?.state == "Karnataka") {
          setTaxType("cgst_sgst");
        } else {
          setTaxType("igst");
        }
      });
    }
  }, [pincode]);

  const onChangeInput = (value: string, index: number, name: string) => {
    var arr = data?.estimate_additionals;
    arr[index][name] = value;
    var amount = arr[index]["amount"];
    var percentage = arr[index].tax_value;
    var final_amount = Number(
      Number(amount) + Number(amount) * Number(percentage) * 0.01
    ).toFixed(2);
    arr[index]["total"] = Number(final_amount);
    setData("estimate_additionals", [...arr]);

    // setPFIEditAdditionalCharges([...arr]);
    console.log(arr);
  };

  useEffect(() => {
    if (pincode?.length == 6) {
      getAddressFromPincode(pincode).then((res: any) => {
        if (res?.state == "Karnataka") {
          setTaxType("cgst_sgst");
        } else {
          setTaxType("igst");
        }
      });
    }
  }, [pincode]);

  return (
    <React.Fragment>
      <div className="my-2">
        <hr />
      </div>
      <Form
        name="dynamic_form_nest_item"
        onFinish={onFinish}
        autoComplete="off"
      >
        {data?.estimate_additionals?.map((each: any, index: number) => (
          <div
            key={index.toString()}
            style={{
              marginBottom: 8,
              background: "#F9F8F6",
              borderRadius: 8,
              padding: 2,
            }}
            // align="baseline"
          >
            <Space
              style={{
                display: "flex",
              }}
            >
              <Form.Item className="formItemPFIEditAdditionalCharges">
                <Input
                  onChange={(e) => {
                    onChangeInput(e.target.value, index, "charge_type");
                  }}
                  value={each.charge_type}
                  placeholder="Charge"
                />

                {/* <Select
                  onChange={(e) => {
                    onChangeInput(e, index, "charge_type");
                  }}
                  value={each.charge_type}
                  placeholder="Select charge type"
                >
                  {ChargeTypeChoices?.map((obj) => (
                    <Option key={obj} value={obj}>
                      {obj}
                    </Option>
                  ))}
                </Select> */}
              </Form.Item>

              {each?.charge_type?.toLowerCase()?.includes("discount") && (
                <div
                  className="text-xs align-items-center d-flex"
                  style={{ whiteSpace: "nowrap", marginBottom: 24 }}
                >
                  Tax applied per item
                </div>
              )}

              <Form.Item
                className="formItemPFIEditAdditionalCharges"
                style={{
                  display: each?.charge_type
                    ?.toLowerCase()
                    ?.includes("discount")
                    ? "none"
                    : "inherit",
                }}
              >
                <Select
                  className="w-100"
                  onChange={(e) => {
                    onChangeInput(e, index, "tax_type");
                  }}
                  placeholder="Select tax type"
                  value={each?.tax_type}
                >
                  {taxTypes?.map((obj) => (
                    <Option key={obj?.id} value={obj?.value}>
                      {obj?.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                className="formItemPFIEditAdditionalCharges"
                style={{
                  display: each?.charge_type
                    ?.toLowerCase()
                    ?.includes("discount")
                    ? "none"
                    : "inherit",
                }}
              >
                <Select
                  className="w-100"
                  onChange={(e) => {
                    onChangeInput(e, index, "tax_value");
                  }}
                  placeholder="Select tax value"
                  value={each?.tax_value}
                >
                  {[0, 5, 12, 18, 28]?.map((obj) => (
                    <Option key={obj} value={obj}>
                      {obj}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item className="formItemPFIEditAdditionalCharges">
                <Input
                  value={each.amount}
                  // formatter={(value: any) => `₹ ${value}`}
                  // parser={(value: string) => value.replace("₹", "")}
                  onChange={(e) => {
                    onChangeInput(e.target.value, index, "amount");
                  }}
                  placeholder="Amount"
                />
              </Form.Item>
              <Form.Item className="formItemPFIEditAdditionalCharges">
                <Input disabled value={"₹ " + each?.total} />
              </Form.Item>
              <MinusCircleOutlined
                onClick={() => {
                  onRemove(index);
                }}
                style={{ marginBottom: 24 }}
              />
            </Space>
            <Form.Item className="formItemPFIEditAdditionalCharges">
              <TextArea
                value={each.remark}
                // formatter={(value: any) => `₹ ${value}`}
                // parser={(value: string) => value.replace("₹", "")}
                onChange={(e) => {
                  onChangeInput(e.target.value, index, "remark");
                }}
                style={{ fontSize: 12 }}
                placeholder="Remarks"
              />
            </Form.Item>
            {each?.charge_type === "Transport Charges" && (
              <Space>
                <Checkbox
                  onChange={(ev) => {
                    if (ev.target.checked) {
                      // Make the transporation charge 899 after tax

                      var arr = data?.estimate_additionals;
                      arr[index]["total"] = 899;
                      const tax = arr[index].tax_value;
                      const amount = 899 / (1 + tax * 0.01);
                      arr[index]["amount"] = Number(amount).toFixed(2);
                      arr[index]["remark"] = "Express Delivery";
                      setData("estimate_additionals", [...arr]);
                    }
                  }}
                />
                <span>Express Delivery</span>
              </Space>
            )}
          </div>
        ))}
        <Form.Item className="">
          <Button
            type="dashed"
            onClick={() => {
              onAdd();
            }}
            block
            icon={<PlusOutlined />}
          >
            Additional Charges
          </Button>
        </Form.Item>
      </Form>
    </React.Fragment>
  );
};

export default PFICreateAdditionalCharges;
